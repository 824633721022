import React, { useState } from "react";
import { ToggleButton, Container, Spinner, Breadcrumb, Button } from "react-bootstrap";
import TouchPointTable from "./TouchPointTable";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom"
import { getCustomerTouchPoints } from "../../ApiConnection"
import { useAuthStore } from '../../stores/AuthStore'
import SearchBar from "../SearchBar";
import { getHelmet, simpleDateSort } from "../../UtilFunctions";

const CustomerTouchPoints = () => {
    const { token, tenant } = useAuthStore();
    const [touchPoints, setTouchPoints] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [active, setActive] = useState(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const location = useLocation();
    const navigate = useNavigate();
    const routeChange = () => {
        const path = `/create-touchpoint`;
        navigate(path);
    }

    const filterTouchPoints = (touchPoint) => {
        if(active) {
           return ( touchPoint.customer.toLowerCase().includes(searchValue)
            || touchPoint.project.toLowerCase().includes(searchValue)
            || touchPoint.contact.name.toLowerCase().includes(searchValue) || touchPoint.contact.lastname.toLowerCase().includes(searchValue)
            || touchPoint.intern_contact.name.toLowerCase().includes(searchValue) || touchPoint.intern_contact.lastname.toLowerCase().includes(searchValue)) && touchPoint.active === +active
        }
        return touchPoint.customer.toLowerCase().includes(searchValue)
        || touchPoint.project.toLowerCase().includes(searchValue)
        || touchPoint.contact.name.toLowerCase().includes(searchValue) || touchPoint.contact.lastname.toLowerCase().includes(searchValue)
        || touchPoint.intern_contact.name.toLowerCase().includes(searchValue) || touchPoint.intern_contact.lastname.toLowerCase().includes(searchValue);
    
    }

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Customer Touch Points Table', 'Listing of all customer touch points', true);
    }

    React.useEffect(() => {
        async function getData() {
            try {
                setTouchPoints(await getCustomerTouchPoints(token, tenant));
                setLoading(false);
            } catch(e) {
                setError(e);
                setLoading(false);
            }
        }
        getData()
    }, []);

    React.useEffect(() => {
        if (error) {
            const status = error.response.status;
            navigate(`/NotAuthenticated/${status}`, {
                state: { prevLocation: location.pathname }
            });
        }
    }, [error]);

    function Table(){
        if(error) {
            const status = error.response.status;
            navigate(`/NotAuthenticated/${status}`);
            return(
                <p>{error.toString()}</p>
            )
        } else {
            return(
            <div className="d-flex justify-content-center">
                {loading ? <Spinner className='mt-2' animation="border" /> : <TouchPointTable touchPoints={touchPoints.filter(filterTouchPoints).sort(simpleDateSort)} />}
            </div>
            )
        }
    }

    return (
        <Container>
            <Helmet />
            <div className="d-flex flex-column">
                <Breadcrumb className="mt-2">
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Customer touchpoints</Breadcrumb.Item>
                </Breadcrumb>
                <div className="d-flex justify-content-center align-items-center">
                    <SearchBar className="w-75" setSearchValue={setSearchValue} searchingOn={'customer, project, internal contact, contact'}></SearchBar>
                    <ToggleButton
                        className="w-10 ms-2"
                        id="toggle-check"
                        type="checkbox"
                        variant="outline-dark"
                        checked={active}
                        value="1"
                        onChange={(e) => setActive(e.currentTarget.checked)}>
                        Active
                    </ToggleButton>
                </div>
            </div>
            <Table></Table>
        </Container>
    );
}

export default CustomerTouchPoints;
