import { Dropdown } from "react-bootstrap";

const AggregationTypeDropdown = ({ aggregationType, setAggregationType }) => {
    return (
        <Dropdown>
            <Dropdown.Toggle variant="dark">
                {aggregationType}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item onClick={() => setAggregationType('day')}>day</Dropdown.Item>
                <Dropdown.Item onClick={() => setAggregationType('month')}>month</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default AggregationTypeDropdown;