import { useParams, Link, useNavigate } from 'react-router-dom'
import { Breadcrumb, Form, Alert, Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { getEmployeeAssignmentIncome, updateEmployeeAssignmentIncome } from '../../ApiConnection'
import { getHelmet } from '../../UtilFunctions';
import { useAuthStore } from '../../stores/AuthStore';

const EditEmployeeAssignmentIncome = () => {
    const { token, tenant } = useAuthStore();
    const { id, incomeid } = useParams();
    const [income, setIncome] = useState(null);
    const [from, setFrom] = useState(new Date());
    const [amount, setAmount] = useState(0);
    const [active, setActive] = useState(true);
    const [error, setError] = useState();
    

    const navigate = useNavigate();

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Employee Assignment Income Edit', 'Make edits to employee assignment income', true);
    }

    useEffect(() => {
        async function getData() {
            const income = await getEmployeeAssignmentIncome(token, tenant, incomeid);
            setFrom(new Date(income.from_date));
            setAmount(income.amount);
            setActive(income.active === 1 ? true : false)
            setIncome(income);
        }
        getData()
    }, [token, tenant]);

    const handleSubmit = async event => {
        event.preventDefault();

        try {
            await updateEmployeeAssignmentIncome(token, tenant, incomeid, from, amount, active)
            navigate(`/assignments/${id}`);
        } catch (error) {
            setError(error.response.data)
        }
    }

    return (
        <div>
            <Helmet />
            <Breadcrumb className="mt-3">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/assignments" }} >Assignments</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/assignments/${id}` }}>Employee Assignment Details</Breadcrumb.Item>
                <Breadcrumb.Item active>Edit Employee Assignment Income</Breadcrumb.Item>
            </Breadcrumb>
            <h5>Edit Employee Assignment Income</h5>
            {(income &&
                <form onSubmit={handleSubmit} >
                    <Form.Group className='mb-3'>
                        <Form.Label>From <span className='text-danger'>*</span></Form.Label>
                        <Form.Control onChange={e => setFrom(new Date(e.target.value))} type="date" value={DateTime.fromJSDate(from).toFormat('yyyy-MM-dd')} required/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Amount <span className='text-danger'>*</span></Form.Label>
                        <Form.Control type="number" onChange={e => setAmount(e.target.value.trim() === '' && !isNaN(e.target.value) ? e.target.value.trim() : e.target.value.trimStart())} value={amount} required />
                    </Form.Group>
                    {error &&
                        <Alert className={`mb-3 ${error ? 'd-none': ''}`} variant="danger">
                            {error}
                        </Alert>
                    }
                    <Button variant="dark" type="submit">Edit employee assignment income</Button>
                </form>
            )}
        </div>
    );
}

export default EditEmployeeAssignmentIncome;
