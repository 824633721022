import React, { useState } from "react";
import { Button, ToggleButton, Container, Spinner, Breadcrumb } from "react-bootstrap";
import CustomerTable from "./CustomerTable";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom"
import { getCustomers } from "../../ApiConnection"
import { useAuthStore } from "../../stores/AuthStore";
import SearchBar from "../SearchBar";
import { getHelmet } from "../../UtilFunctions";

const Customers = () => {
    const { token, tenant } = useAuthStore();
    const [customers, setCustomers] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(true);
    const [active, setActive] = useState(true);
    const [error, setError] = useState(null);

    const location = useLocation();
    const navigate = useNavigate();

    const routeChange = () => {
        const path = `/customers/create`;
        navigate(path);
    }

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Customer Table', 'Listing of all customers', true);
    }

    const filterCustomers = (customer) => {
        if(active) {
            return customer.name.toLowerCase().includes(searchValue) && customer.active === +active;
        }
        return customer.name.toLowerCase().includes(searchValue);
    }

    React.useEffect(() => {
        async function getData() {
            try {
                setCustomers(await getCustomers(token, tenant));
                setLoading(false);
            } catch (e) {
                setError(e);
                setLoading(false);
            }
        }
        getData()
    }, []);

    function Table() {
        if (error) {
            const status = error.response.status;
            navigate(`/NotAuthenticated/${status}`, {
                state: { prevLocation: location.pathname }
            });
            return (
                <div>
                    <p>{error.toString()}</p>
                </div>
            )
        } else {
            return (
                <div className="d-flex justify-content-center">
                    {loading ? <Spinner className='mt-2' animation="border" /> : <CustomerTable customers={customers.filter(filterCustomers).sort((a, b) => a.name > b.name ? 1 : -1)} />}
                </div>
            )
        }
    }


    return (
        <Container>
            <Helmet />
            <div className="d-flex flex-column">
                <Breadcrumb className="mt-2">
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Customers</Breadcrumb.Item>
                </Breadcrumb>
                <div className="d-flex justify-content-center align-items-center">
                    <SearchBar className="w-75" setSearchValue={setSearchValue} searchingOn={'name'}></SearchBar>
                    <ToggleButton
                        className="w-10 ms-2"
                        id="toggle-check"
                        type="checkbox"
                        variant="outline-dark"
                        checked={active}
                        value="1"
                        onChange={(e) => setActive(e.currentTarget.checked)}>
                        Active
                    </ToggleButton>
                    <Button className="w-25 ms-2" onClick={routeChange} variant="dark">Add Customer</Button>
                </div>
            </div>
            <Table></Table>
        </Container>
    );
}

export default Customers;
