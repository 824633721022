import React from "react";
import { Container, ListGroup, Col, Row } from "react-bootstrap";
import { calculateTimeBetween } from "../../UtilFunctions";
import { observer } from "mobx-react";

const Comment = ({ comment }) => {
  return (
    <ListGroup.Item>
      <Container fluid>
        <Row>
          <Col>
            <h6 className="fw-bold mb-1">{comment.created_by}</h6>
            <p className="mb-3">
              {calculateTimeBetween(comment.created_at)}                 {comment.created_at !== comment.modified_at ? (
                <small className="fs-6 ms-2 text-muted">(edited)</small>
              ) : (
                ""
              )}
            </p>
          </Col>
        </Row>
        <p className="mb-0">
          {comment.text}
        </p>
      </Container>
    </ListGroup.Item>
  );
};

export default observer(Comment);
