import { Form, Table } from "react-bootstrap";

const RoleTable = ({ roles, addToSelectedRoles, removeFromSelectedRoles }) => {
  return (
    <Table striped bordered hover className="mt-2">
      <thead>
        <tr>
          <th></th>
          <th>role</th>
        </tr>
      </thead>
      <tbody>
        {roles.map((role) => (
          <tr>
            <td>
              <Form.Check
                type="checkbox"
                onClick={(e) => {
                  if(e.target.checked) addToSelectedRoles(role.role_name);
                  else removeFromSelectedRoles(role.role_name);
                  role.checked = e.target.checked;
                }}
                checked={role.checked}
              ></Form.Check>
            </td>
            <td>{role.role_name}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default RoleTable;
