import { useState } from "react";
import { Form, Alert, Breadcrumb, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { ExclamationCircleFill } from "react-bootstrap-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { adminAddUser } from "../../../ApiConnection";
import { useAuthStore } from "../../../stores/AuthStore";
import { getHelmet } from "../../../UtilFunctions";

const CreateUser = () => {
  const { token } = useAuthStore();
  const { id } = useParams();
  const [cronosUser, setCronosUser] = useState(false);
  const [userToCreate, setUserToCreate] = useState({
    username: "",
    email: "",
    password: "",
    permanentPassword: false,
  });
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const result = await adminAddUser(token, id, cronosUser ? { ...userToCreate, username: `cronos_${userToCreate.username}`} : userToCreate);
      navigate(`/manage/${id}/users`);
    } catch (e) {
      setError(e.response.data);
    }
  };

  const Helmet = () => {
    return getHelmet('Cloudway Accounts - User Creation', 'Creation of user', true);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        The username will automatically be prepended with 'cronons_'. This is necessary for Cronos users.
    </Tooltip>
  );

  return (
    <div>
      <Helmet />
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/manage/${id}/users` }}>
          Users
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Create User</Breadcrumb.Item>
      </Breadcrumb>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
            <Form.Check.Label>
                Cronos <OverlayTrigger placement="bottom" overlay={renderTooltip}><ExclamationCircleFill className="fs-7" /></OverlayTrigger>
            </Form.Check.Label>
            <Form.Check className="d-inline ms-2" type="switch" onChange={e => setCronosUser(e.target.checked)} checked={cronosUser} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Username</Form.Label>
          <Form.Control
            onChange={(e) =>
              setUserToCreate({ ...userToCreate, username: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            onChange={(e) =>
              setUserToCreate({ ...userToCreate, email: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            onChange={(e) =>
              setUserToCreate({ ...userToCreate, password: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Check
            type="switch"
            onChange={(e) =>
              setUserToCreate({
                ...userToCreate,
                permanentPassword: e.target.checked,
              })
            }
            label="Permanent password"
          />
        </Form.Group>
        {error && (
          <Alert className="mb-3" variant="danger">
            {error}
          </Alert>
        )}
        <Button variant="dark" type="submit">
          Add user
        </Button>
      </Form>
    </div>
  );
};

export default CreateUser;
