import { Dropdown } from "react-bootstrap";

const SortDropdown = ({ sort, setSort }) => {
    return (
        <Dropdown>
            <Dropdown.Toggle variant="dark">
                {sort}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item onClick={() => setSort('desc')}>High - Low</Dropdown.Item>
                <Dropdown.Item onClick={() => setSort('asc')}>Low - High</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default SortDropdown;