import { Breadcrumb, Button, Modal, Row, Col, Tab, Tabs } from 'react-bootstrap'
import { useNavigate, Link, useParams } from 'react-router-dom'
import React, { useState } from "react";
import { getEmployeeDetail, deleteEmployee, getAllCostsOfEmployee, getCurrentEmployeeCost } from '../../ApiConnection'
import { formatDate, calculateTimeBetween, getHelmet } from '../../UtilFunctions';
import EmployeeAssignmentTableCompact from '../assignment/EmployeeAssignmentTableCompact';
import { useAuthStore } from '../../stores/AuthStore';
import { DateTime } from 'luxon';
import AssignmentVisualization from './AssignmentVisualization';
import { CostIncomeBarChart, IncomeBarChart } from '../charts';
import EmployeeCostTable from '../cost/EmployeeCostTable';
import { observer } from 'mobx-react';

const EmployeeDetail = () => {
    const { token, tenant, roles } = useAuthStore();
    const { id } = useParams();
    const [employee, setEmployee] = React.useState(null);
    const [costs, setCosts] = useState([]);
    const [currentCost, setCurrentCost] = useState(null);
    const [show, setShow] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleDelete = async (id) => {
        await deleteEmployee(token, tenant, id);
        const path = `/employees`;
        navigate(path);
    }

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Employee Details', 'Details of an employee', true);
    }

    const navigate = useNavigate();
    const routeChange = (id) => {
        const path = `/employees/edit/${id}`;
        navigate(path);
    }

    const getEmployeeCostData = async () => {
        try {
            setCosts(await getAllCostsOfEmployee(token, tenant, id));
            setCurrentCost(await getCurrentEmployeeCost(token, tenant, id));
        } catch (e) {
            setCurrentCost('NO COST');
        }
    }

    React.useEffect(() => {
        async function getData() {
            setEmployee(await getEmployeeDetail(token, tenant, id));
            if (roles.includes('Financial')) getEmployeeCostData();
        }
        getData()
    }, []);

    if (!employee) return null;

    return (
        <div>
            <Helmet />
            <Breadcrumb className="mt-3">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/employees" }} >Employees</Breadcrumb.Item>
                <Breadcrumb.Item active>Employee Details</Breadcrumb.Item>
            </Breadcrumb>
            <Row>
                <Col>
                    <h5>Employee details</h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>First name</small>
                    <p>{employee.name}</p>
                </Col>
                <Col>
                    <small className='text-muted'>Last name</small>
                    <p>{employee.lastname}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>Start date</small>

                    <p>{formatDate(employee.start_date)}</p>
                </Col>
                <Col>
                    <small className='text-muted'>Active</small>

                    <p>{employee.active ? 'Yes' : 'No'}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>Created</small>
                    <p>{calculateTimeBetween(employee.created_at)}</p>
                </Col>
                <Col>
                    <small className='text-muted'>Created by</small>
                    <p>{employee.created_by}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>Last modified</small>

                    <p>{calculateTimeBetween(employee.modified_at)}</p>
                </Col>
                <Col>
                    <small className='text-muted'>Last modified by</small>
                    <p>{employee.last_modified_by}</p>
                </Col>
            </Row>
            {roles.includes('Financial') &&
                <Row>
                    <Col>
                        <small className='text-muted'>Current daily cost</small>
                        <p>{currentCost?.amount ? `€${currentCost.amount}` : currentCost}</p>
                    </Col>
                </Row>
            }
            <Row>
                <Col>
                    <Button onClick={() => routeChange(employee.id)} variant="dark">Edit</Button>{' '}
                    <Button onClick={handleShow} variant="dark">Remove</Button>
                </Col>
            </Row>
            <Tabs defaultActiveKey="assignmentsvisualization" className="mb-3 mt-2" id="uncontrolled-tab-example">
                <Tab eventKey="assignmentsvisualization" title="Assignment visualization">
                    <h5>Assignment Visualization</h5>
                    <AssignmentVisualization allAssignments={employee.employee_assignments}></AssignmentVisualization>
                </Tab>
                {roles.includes('Financial') ?
                    <Tab eventKey="costIncomeChart" title="Cost/Income visualization">
                        <h5>Cost-Income</h5>
                        <CostIncomeBarChart on='employee' refresh />
                    </Tab>
                : roles.includes('Sales') ?
                    <Tab eventKey="incomeChart" title="Income visualization">
                        <h5>Income</h5>
                        <IncomeBarChart on='employee' refresh />
                    </Tab>
                : null
                }
                <Tab eventKey="assignmentshistory" title="Assignment history">
                    <h5>Assignment history</h5>
                    <EmployeeAssignmentTableCompact assignments={employee.employee_assignments.filter(emp => DateTime.fromISO(emp.end_date) < DateTime.now()).map(empAssignment => ({ ...empAssignment, employee_firstname: employee.name, employee_lastname: employee.lastname }))} companyView={false} />
                </Tab>
                <Tab eventKey="assignments" title="Current assignments">
                    <div className='d-flex'>
                        <h5>Current assignments</h5>
                        <Button className="w-25 ms-auto" onClick={() => navigate(`/assignments/create`, { state: { employee: id, name: employee.name, lastname: employee.lastname } })} variant="dark">Add Assignment</Button>
                    </div>
                    <EmployeeAssignmentTableCompact assignments={employee.employee_assignments.filter(emp => DateTime.fromISO(emp.start_date) <= DateTime.now() && DateTime.fromISO(emp.end_date) >= DateTime.now()).map(empAssignment => ({ ...empAssignment, employee_firstname: employee.name, employee_lastname: employee.lastname }))} companyView={false} />
                </Tab>
                <Tab eventKey="futureassignments" title="Future assignments">
                    <h5>Future assignments</h5>
                    <EmployeeAssignmentTableCompact assignments={employee.employee_assignments.filter(emp => DateTime.fromISO(emp.start_date) > DateTime.now()).map(empAssignment => ({ ...empAssignment, employee_firstname: employee.name, employee_lastname: employee.lastname }))} companyView={false} />
                </Tab>
                {roles.includes('Financial') &&
                    <Tab eventKey="costs" title="Cost">
                        <div className='d-flex'>
                            <h5>Employee Cost</h5>
                            <Button className="w-25 ms-auto" onClick={() => navigate(`/employees/${id}/cost/create`)} variant="dark">Add Daily Cost</Button>
                        </div>
                        <EmployeeCostTable costs={costs.map(co => ({ ...co, name: employee.name, lastname: employee.lastname }))} refreshEmployeeCosts={getEmployeeCostData} current={currentCost?.id} ></EmployeeCostTable>
                    </Tab>
                }
            </Tabs>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Employee</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure, you want to delete this Employee!</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="dark" onClick={() => { handleDelete(employee.id) }}>
                        Delete employee
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default observer(EmployeeDetail);
