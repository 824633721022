import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb, Button, Container, Spinner } from "react-bootstrap";
import { getTenants } from "../../../ApiConnection";
import { useAuthStore } from "../../../stores/AuthStore";
import SearchBar from "../../SearchBar";
import TenantTable from "./TenantTable";
import { getHelmet } from "../../../UtilFunctions";

const Tenants = () => {
  const { token } = useAuthStore();
  const [tenants, setTenants] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  const Helmet = () => {
    return getHelmet('Cloudway Accounts - Tenants Table', 'Listing of all tenants', true);
  }

  const getData = async () => {
    try {
      setTenants(await getTenants(token));
      setLoading(false);
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  };

  const filterTenants = (tenant) =>
    tenant.name.toLowerCase().includes(searchValue);

  useEffect(() => {
    getData();
  }, []);

  const Table = () =>
    error ? (
      navigate(`/NotAuthenticated/${error.response.status}`, {
        state: { prevLocation: location.pathname },
      })
    ) : (
      <div className="d-flex justify-content-center">
        {loading ? (
          <Spinner className="mt-2" animation="border" />
        ) : (
          <TenantTable tenants={tenants.filter(filterTenants)} />
        )}
      </div>
    );

  return (
    <Container>
      <Helmet />
      <div className="d-flex flex-column">
        <Breadcrumb className="mt-2">
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: "/admin-panel/tenants" }}
          >
            Tenants
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="d-flex justify-content-center align-items-center">
          <SearchBar
            className="w-75"
            setSearchValue={setSearchValue}
            searchingOn={"name"}
          ></SearchBar>
          <Button
            className="w-50 ms-2"
            onClick={() => navigate("/admin-panel/tenants/create")}
            variant="dark"
          >
            Add Tenant
          </Button>
        </div>
      </div>
      <Table></Table>
    </Container>
  );
};

export default Tenants;
