import { useEffect, useState } from 'react';
import { Button, ToggleButton, Container, Spinner, Breadcrumb } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom"
import { getContacts } from "../../ApiConnection"
import { useAuthStore } from "../../stores/AuthStore";
import { getHelmet } from '../../UtilFunctions';
import SearchBar from "../SearchBar";
import ContactTable from './ContactTable';

const Contacts = () => {
    const { token , tenant } = useAuthStore();
    const [contacts, setContacts] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [active, setActive] = useState(true);

    const location = useLocation();
    const navigate = useNavigate();

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Contact Table', 'Listing of all contact', true);
    }

    const filterContacts = (contact) => {
        if(active) {
            return (contact.name.toLowerCase().includes(searchValue) || contact.lastname.toLowerCase().includes(searchValue) || contact.email.toLowerCase().includes(searchValue)) && contact.active === +active;
        }
        return contact.name.toLowerCase().includes(searchValue) || contact.lastname.toLowerCase().includes(searchValue) || contact.email.toLowerCase().includes(searchValue);
    }

    useEffect(() => {
        async function getData() {
            try {
                setContacts((await getContacts(token, tenant)).sort((a, b) => a.name > b.name ? 1 : -1));
                setLoading(false);
            } catch (e) {
                setError(e);
                setLoading(false);
            }
        }
        getData()
    }, [token, tenant]);

    useEffect(() => {
        if (error) {
            const status = error.response.status;
            navigate(`/NotAuthenticated/${status}`, {
                state: { prevLocation: location.pathname }
            });
        }
    }, [error]);
    
    function Table() {
        return (
            <div className="d-flex justify-content-center">
                {loading ? <Spinner className='mt-2' animation="border" /> : <ContactTable contacts={contacts.filter(filterContacts).sort((a, b) => a.name > b.name ? 1 : -1)} />}
            </div>
        )
    };

    return (
        <Container>
            <Helmet />
            <div className="d-flex flex-column">
                <Breadcrumb className="mt-2">
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Contacts</Breadcrumb.Item>
                </Breadcrumb>
                <div className="d-flex justify-content-center align-items-center">
                    <SearchBar className="w-75" setSearchValue={setSearchValue} searchingOn={'first name, lastname, email'}></SearchBar>
                    <ToggleButton
                        className="w-10 ms-2"
                        id="toggle-check"
                        type="checkbox"
                        variant="outline-dark"
                        checked={active}
                        value="1"
                        onChange={(e) => setActive(e.currentTarget.checked)}>
                        Active
                    </ToggleButton>
                    <Button className="w-25 ms-2" onClick={() => navigate(`/contacts/create`)} variant="dark">Add Contact</Button>
                </div>
            </div>
            <Table></Table>
        </Container>
    );
}

export default Contacts;