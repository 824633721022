import axios from "axios";
import { formatHourDate } from "./UtilFunctions";

const baseURL = process.env.REACT_APP_API_URL;

//#region /version endpoint
export const getAPIVersion = async (token) => {
  const resp = await axios.get(`${baseURL}/version`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return resp.data.version;
};
//#endregion

//#region /employeeassignment endpoint
export const getEmployeeAssignments = async (token, tenant) => {
  const resp = await axios.get(`${baseURL}/${tenant}/employeeassignment`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return resp.data;
};

export const getEmployeeAssignmentDetail = async (token, tenant, id) => {
  const resp = await axios.get(
    `${baseURL}/${tenant}/employeeassignment/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return resp.data;
};

export const addEmployeeAssignment = async (
  token,
  tenant,
  employee,
  project,
  startDate,
  endDate,
  renewalExpected,
  billable,
  allocationPercentage
) => {
  const body = {
    employee_id: employee,
    project_id: project,
    start_date: formatHourDate(startDate),
    end_date: formatHourDate(endDate),
    renewal_expected: renewalExpected,
    billable,
    allocation_percentage: parseFloat(allocationPercentage / 100).toFixed(2),
  };

  const resp = await axios.post(
    `${baseURL}/${tenant}/employeeassignment`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return resp;
};

export const deleteEmployeeAssignment = async (token, tenant, id) => {
  await axios.delete(`${baseURL}/${tenant}/employeeassignment/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateEmployeeAssignment = async (
  token,
  tenant,
  id,
  employee,
  project,
  startDate,
  endDate,
  renewalExpected,
  billable,
  allocationPercentage,
  active
) => {
  const body = {
    id,
    employee_id: employee,
    project_id: project,
    start_date: startDate,
    end_date: endDate,
    renewal_expected: renewalExpected,
    billable,
    allocation_percentage: parseFloat(allocationPercentage / 100).toFixed(2),
    active: active,
  };

  await axios.put(`${baseURL}/${tenant}/employeeassignment/${id}`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
//#endregion

//#region /employee endpoint
export const getEmployees = async (token, tenant) => {
  const resp = await axios.get(`${baseURL}/${tenant}/employee`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return resp.data;
};

export const getAvailableEmployees = async (token, tenant, params) => {
  const resp = await axios.get(`${baseURL}/${tenant}/employee/available`, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return resp.data;
};

export const getEmployeeDetail = async (token, tenant, id) => {
  const resp = await axios.get(`${baseURL}/${tenant}/employee/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return resp.data;
};

export const addEmployee = async (
  token,
  tenant,
  name,
  lastname,
  startDate,
  endDate
) => {
  const body = {
    name,
    lastname,
    start_date: startDate,
  };

  if (endDate) body.end_date = endDate;

  const resp = await axios.post(`${baseURL}/${tenant}/employee`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return resp;
};

export const deleteEmployee = async (token, tenant, id) => {
  await axios.delete(`${baseURL}/${tenant}/employee/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateEmployee = async (
  token,
  tenant,
  id,
  name,
  lastname,
  startDate,
  endDate,
  active
) => {
  const body = {
    id,
    name,
    lastname,
    start_date: startDate,
    end_date: endDate,
    active,
  };

  await axios.put(`${baseURL}/${tenant}/employee/${id}`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
//#endregion

//#region /customer endpoint
export const getCustomers = async (token, tenant) => {
  return (
    await axios.get(`${baseURL}/${tenant}/customer`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).data;
};

export const getCustomerDetail = async (token, tenant, id) => {
  return (
    await axios.get(`${baseURL}/${tenant}/customer/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).data;
};

export const addCustomer = async (token, tenant, name) => {
  const body = {
    name,
  };

  return axios.post(`${baseURL}/${tenant}/customer`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteCustomer = async (token, tenant, id) => {
  return axios.delete(`${baseURL}/${tenant}/customer/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateCustomer = async (
  token,
  tenant,
  id,
  name,
  track_contact_activity,
  active
) => {
  const body = {
    id,
    name,
    track_contact_activity,
    active,
  };

  return axios.put(`${baseURL}/${tenant}/customer/${id}`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
//#endregion

//#region /statistics endpoint
export const getAllocation = async (token, tenant, params) => {
  return (
    await axios.get(`${baseURL}/${tenant}/statistics/allocation`, {
      params,
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;
};

export const getLowestAllocation = async (token, tenant, amount) => {
  return (
    await axios.get(`${baseURL}/${tenant}/statistics/lowestallocation`, {
      params: { amount },
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;
};

export const getLatestNegativeTouchPoint = async (token, tenant, amount) => {
  const resp = await axios.get(
    `${baseURL}/${tenant}/customertouchpoint/latestnegative`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return resp.data;
};

export const getStaleTouchPoint = async (token, tenant, amount) => {
  const resp = await axios.get(
    `${baseURL}/${tenant}/customertouchpoint/stale`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return resp.data;
};

export const checkAllocation = async (
  token,
  tenant,
  threshold = 85,
  months = 3
) => {
  const params = new URLSearchParams([
    ["threshold", threshold],
    ["months", months],
  ]);

  return (
    await axios.get(`${baseURL}/${tenant}/statistics/allocationcheck`, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).data;
};

//#endregion

//#region /project endpoint
export const getProjects = async (token, tenant) => {
  return (
    await axios.get(`${baseURL}/${tenant}/project`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).data;
};

export const getprojectDetail = async (token, tenant, id) => {
    return (await axios.get(`${baseURL}/${tenant}/project/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })).data;
}

export const addProject = async (token, tenant, name, startDate, endDate, customer, description, internalResponsible, customerResponsible, accountManager) => {
    const body = {
        name,
        start_date: startDate,
        end_date: endDate,
        customer,
        internal_responsible: internalResponsible,
        customer_responsible: customerResponsible || null,
        description: description || '',
        account_manager: accountManager || ''
    }

    return (await axios.post(`${baseURL}/${tenant}/project`, body, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })).data;
}

export const deleteproject = async (token, tenant, id) => {
    await axios.delete(`${baseURL}/${tenant}/project/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
}

export const updateProject = async (token, tenant, id, name, startDate, endDate, customer, description, internalResponsible, customerResponsible, accountManager, active) => {
    const body = {
        id,
        name,
        start_date: startDate,
        end_date: endDate,
        customer,
        description: description || '',
        internal_responsible: internalResponsible,
        customer_responsible: customerResponsible === '' ? null : customerResponsible,
        account_manager: accountManager || '',
        active: true
    };

    await axios.put(`${baseURL}/${tenant}/project/${id}`, body, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
}
//#endregion

//#region /customertouchpoint

export const getCustomerTouchPoints = async (token, tenant) => {
  const resp = await axios.get(`${baseURL}/${tenant}/customertouchpoint`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return resp.data;
};

export const getCustomerTouchPoint = async (token, tenant, id) => {
  const resp = await axios.get(
    `${baseURL}/${tenant}/customertouchpoint/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return resp.data;
};

export const getCustomerTouchPointsByCustomer = async (token, tenant, id) => {
  const resp = await axios.get(
    `${baseURL}/${tenant}/customer/${id}/touchpoints`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return resp.data;
};

export const addCustomerTouchPoint = async (
  token,
  tenant,
  sentiment,
  date,
  comment,
  contact,
  intern_contact,
  assignments,
  project_id
) => {
  const body = {
    sentiment,
    date: formatHourDate(date),
    contact,
    intern_contact,
    project: project_id,
  };

  if (comment) body.comment = comment;

  if (assignments) {
    const correctFormat = [];
    assignments.forEach((element) => {
      correctFormat.push({ employee_assignment_id: element.value.id });
    });
    body.assignments = correctFormat;
  }

  const resp = await axios.post(
    `${baseURL}/${tenant}/customertouchpoint`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return resp;
};

export const deleteCustomerTouchPoint = async (token, tenant, id) => {
  await axios.delete(`${baseURL}/${tenant}/customertouchpoint/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateCustomerTouchPoint = async (
  token,
  tenant,
  id,
  sentiment,
  date,
  comment,
  contact,
  intern_contact,
  project_id,
  assignments,
  active
) => {
  const body = {
    id,
    sentiment,
    date: formatHourDate(date),
    comment,
    contact,
    intern_contact,
    project: project_id,
    active,
  };

  if (assignments) {
    const correctFormat = [];
    assignments.forEach((element) => {
      correctFormat.push({
        employee_assignment_id: element.value.employee_assignment_id,
      });
    });
    body.assignments = correctFormat;
  }

  await axios.put(`${baseURL}/${tenant}/customertouchpoint/${id}`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

//#endregion

//#region /contact

export const getContacts = async (token, tenant) =>
  (
    await axios.get(`${baseURL}/${tenant}/contact`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).data;

export const getContact = async (token, tenant, id) =>
  (
    await axios.get(`${baseURL}/${tenant}/contact/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;

export const addContact = async (token, tenant, name, lastname, email) =>
  (
    await axios.post(
      `${baseURL}/${tenant}/contact`,
      { name, lastname, email },
      { headers: { Authorization: `Bearer ${token}` } }
    )
  ).data;

export const updateContact = async (
  token,
  tenant,
  id,
  name,
  lastname,
  email,
  active
) =>
  (
    await axios.put(
      `${baseURL}/${tenant}/contact/${id}`,
      {
        id,
        name,
        lastname,
        email,
        active,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    )
  ).data;

export const deleteContact = async (token, tenant, id) =>
  (
    await axios.delete(`${baseURL}/${tenant}/contact/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;

//#endregion

//#region /comment

export const getCommentsOfResource = async (token, tenant, resource) =>
   (
     await axios.get(`${baseURL}/${tenant}/comment/${resource}`, {
       headers: { Authorization: `Bearer ${token}` },
     })
   ).data;

export const addComment = async (token, tenant, resource, text) =>
  (
    await axios.post(
      `${baseURL}/${tenant}/comment`,
      { resource, text },
      { headers: { Authorization: `Bearer ${token}` } }
    )
  ).data;

export const updateComment = async (token, tenant, id, text, active) =>
  (
    await axios.put(
      `${baseURL}/${tenant}/comment/${id}`,
      { id, text, active },
      { headers: { Authorization: `Bearer ${token}` } }
    )
  ).data;

export const deleteComment = async (token, tenant, id) =>
  (
    await axios.delete(`${baseURL}/${tenant}/comment/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;

//#endregion

//#region /profile

export const getProfile = async (token) =>
  (
    await axios.get(`${baseURL}/profile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).data;

export const getRoles = async (token, tenant) =>
  (
    await axios.get(`${baseURL}/${tenant}/roles`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).data;

  export const getFullRoles = async (token, tenant) =>
  (
    await axios.get(`${baseURL}/${tenant}/fullroles`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).data;

//#endregion

//#region /backoffice

export const getTenants = async (token) =>
  (
    await axios.get(`${baseURL}/backoffice/tenant`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).data;

export const getTenant = async (token, tenantId) =>
  (
    await axios.get(`${baseURL}/backoffice/tenant/${tenantId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).data;

export const getAvailableRolesOfTenant = async (token, tenantId) =>
  (
    await axios.get(`${baseURL}/backoffice/tenant/${tenantId}/role`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).data;

export const getUsersFromTenant = async (token, tenant) =>
  (
    await axios.get(`${baseURL}/backoffice/tenant/${tenant}/user`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).data;

export const getTenantsFromUser = async (token, username) =>
  (
    await axios.get(`${baseURL}/backoffice/user/${username}/tenant`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).data;

export const getRolesOfUser = async (token, tenant, username) =>
  (
    await axios.get(
      `${baseURL}/backoffice/tenant/${tenant}/user/${username}/role`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  ).data;

export const addTenant = async (token, name) =>
  (
    await axios.post(
      `${baseURL}/backoffice/tenant`,
      { name },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  ).data;

export const addRoles = async (token, tenant, username, roles) =>
  await axios.post(
    `${baseURL}/backoffice/role`,
    { tenantId: tenant, user: username, roles },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const deleteRoles = async (token, tenant, username, roles) =>
  await axios.delete(
    `${baseURL}/backoffice/role`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { tenantId: tenant, user: username, roles },
    }
  );

export const getUsers = async (token) =>
  (
    await axios.get(`${baseURL}/backoffice/user`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).data;

export const getUser = async (token, username) =>
  (
    await axios.get(`${baseURL}/backoffice/user/${username}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).data;

export const addUser = async (
  token,
  { username, email, password, permanentPassword }
) =>
  (
    await axios.post(
      `${baseURL}/backoffice/user`,
      {
        username,
        email,
        password,
        permanent_password: permanentPassword ?? false,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  ).data;

export const deleteUser = async (token, username) =>
  await axios.delete(`${baseURL}/backoffice/user/${username}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

//#endregion

export const getTenantSettings = async (token, tenant) =>
  (
    await axios.get(`${baseURL}/${tenant}/settings`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).data;

export const updateTenantAlarmSettings = async (token, tenant, threshold, months, finance_months, finance_percentage) =>
  (
    await axios.patch(`${baseURL}/${tenant}/settings`,
    { allocation_alarm_threshold: threshold, allocation_alarm_months: months, finance_alarm_months: finance_months, finance_alarm_percentage: finance_percentage },
    { headers: { Authorization: `Bearer ${token}` } })
  ).data;


//#region /finance

export const getAllCostsOfEmployee = async (token, tenant, id) =>
  (
    await axios.get(`${baseURL}/${tenant}/finance/cost/employee/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;

export const getEmployeeCost = async (token, tenant, id) =>
  (
    await axios.get(`${baseURL}/${tenant}/finance/cost/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;

export const getCurrentEmployeeCost = async (token, tenant, id) =>
  (
    await axios.get(
      `${baseURL}/${tenant}/finance/cost/employee/${id}/current`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
  ).data;

export const addEmployeeCost = async (
  token,
  tenant,
  employee,
  from_date,
  amount
) =>
  (
    await axios.post(
      `${baseURL}/${tenant}/finance/cost/employee`,
      { employee, from_date, amount },
      { headers: { Authorization: `Bearer ${token}` } }
    )
  ).data;

export const updateEmployeeCost = async (
  token,
  tenant,
  id,
  from_date,
  amount,
  active
) =>
  (
    await axios.put(
      `${baseURL}/${tenant}/finance/cost/employee/${id}`,
      { id, from_date, amount, active },
      { headers: { Authorization: `Bearer ${token}` } }
    )
  ).data;

export const deleteEmployeeCost = async (token, tenant, id) =>
  (
    await axios.delete(
      `${baseURL}/${tenant}/finance/cost/employee/${id}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
  ).data;

export const getAllIncomesOfEmployeeAssignment = async (token, tenant, id) =>
  (
    await axios.get(
      `${baseURL}/${tenant}/finance/income/employeeassignment/${id}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
  ).data;

export const getEmployeeAssignmentIncome = async (token, tenant, id) =>
  (
    await axios.get(`${baseURL}/${tenant}/finance/income/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;

export const getCurrentEmployeeAssignmentIncome = async (token, tenant, id) =>
  (
    await axios.get(
      `${baseURL}/${tenant}/finance/income/employeeassignment/${id}/current`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
  ).data;

export const addEmployeeAssignmentIncome = async (
  token,
  tenant,
  employeeAssignment,
  from_date,
  amount
) =>
  (
    await axios.post(
      `${baseURL}/${tenant}/finance/income/employeeassignment`,
      { employeeAssignment, from_date, amount },
      { headers: { Authorization: `Bearer ${token}` } }
    )
  ).data;

export const updateEmployeeAssignmentIncome = async (
  token,
  tenant,
  id,
  from_date,
  amount,
  active
) =>
  (
    await axios.put(
      `${baseURL}/${tenant}/finance/income/employeeassignment/${id}`,
      { id, from_date, amount, active },
      { headers: { Authorization: `Bearer ${token}` } }
    )
  ).data;

export const deleteEmployeeAssignmentIncome = async (token, tenant, id) =>
  (
    await axios.delete(
      `${baseURL}/${tenant}/finance/income/employeeassignment/${id}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
  ).data;

export const getCostIncomeFor = async (token, tenant, id, params) =>
  (
    await axios.get(`${baseURL}/${tenant}/finance/incomecost/${id}`, {
      params,
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;

export const getCostIncomeFull = async (token, tenant, params) =>
  (
    await axios.get(`${baseURL}/${tenant}/finance/incomecost/full`, {
      params,
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;

export const getIncomeFor = async (token, tenant, id, params) =>
(
  await axios.get(`${baseURL}/${tenant}/finance/onlyincome/${id}`, {
    params,
    headers: { Authorization: `Bearer ${token}` },
  })
).data;

export const getIncomeFull = async (token, tenant, params) =>
  (
    await axios.get(`${baseURL}/${tenant}/finance/onlyincome/full`, {
      params,
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;

export const checkFinance = async (
  token,
  tenant,
  percentage = 100,
  months = 3
  ) => {
    const params = new URLSearchParams([
      ["percentage", percentage],
      ["months", months],
    ]);

    return (
      await axios.get(`${baseURL}/${tenant}/statistics/financecheck`, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ).data;
};

//#endregion

export const adminGetTenant = async (token, tenant) =>
  (
    await axios.get(`${baseURL}/${tenant}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;

export const adminGetUsers = async (token, tenant) =>
  (
    await axios.get(`${baseURL}/${tenant}/user`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;

export const adminGetUser = async (token, tenant, username) =>
  (
    await axios.get(`${baseURL}/${tenant}/user/${username}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;

export const adminAddUser = async (
  token,
  tenant,
  { username,
  email,
  password,
  permanentPassword }
) =>
  (
    await axios.post(
      `${baseURL}/${tenant}/user`,
      {
        username,
        email,
        password,
        permanent_password: permanentPassword ?? false,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
  ).data;

export const adminDeleteUser = async (token, tenant, username) =>
  await axios.delete(`${baseURL}/${tenant}/user/${username}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

export const adminGetRolesOfUser = async (token, tenant, username) =>
  (
    await axios.get(`${baseURL}/${tenant}/user/${username}/role`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;

export const adminAddRoles = async (token, tenant, username, roles) =>
  (
    await axios.post(
      `${baseURL}/${tenant}/role`,
      { user: username, roles },
      { headers: { Authorization: `Bearer ${token}` } }
    )
  ).data;

export const adminDeleteRoles = async (token, tenant, username, roles) =>
  (
    await axios.delete(
      `${baseURL}/${tenant}/role`,
      { user: username, roles },
      { headers: { Authorization: `Bearer ${token}` } }
    )
  ).data;


//#region /workperiod endpoint
export const getWorkPeriodDetail = async (token, tenant, id) => {
  const resp = await axios.get(
      `${baseURL}/${tenant}/workperiod/${id}`,
      {
          headers: {
              Authorization: `Bearer ${token}`,
          },
      }
  );
  return resp.data;
};

export const getWorkPeriods = async (token, tenant, employeeAssignmentId) => {
  const resp = await axios.get(`${baseURL}/${tenant}/workperiod/employeeassignment/${employeeAssignmentId}`, {
      headers: {
          Authorization: `Bearer ${token}`,
      },
  });
  return resp.data;
};

export const addWorkPeriod = async (
  token,
  tenant,
  employeeAssignmentId,
  dateFrom,
  dateTo,
  workPeriodType,
  weeklyAllocationPercentage,
  mondayAllocationPercentage,
  tuesdayAllocationPercentage,
  wednesdayAllocationPercentage,
  thursdayAllocationPercentage,
  fridayAllocationPercentage,
  detailedAllocationPercentage) => {
  const body = {
      employee_assignment_id: employeeAssignmentId,
      date_from: formatHourDate(dateFrom),
      date_to: formatHourDate(dateTo),
      work_period_type: workPeriodType,
      weekly_allocation_percentage: parseFloat(weeklyAllocationPercentage / 100).toFixed(2),
      monday_allocation_percentage: parseFloat(mondayAllocationPercentage / 100).toFixed(2),
      tuesday_allocation_percentage: parseFloat(tuesdayAllocationPercentage / 100).toFixed(2),
      wednesday_allocation_percentage: parseFloat(wednesdayAllocationPercentage / 100).toFixed(2),
      thursday_allocation_percentage: parseFloat(thursdayAllocationPercentage / 100).toFixed(2),
      friday_allocation_percentage: parseFloat(fridayAllocationPercentage / 100).toFixed(2),
      detailed_allocation_percentage: parseFloat(detailedAllocationPercentage / 100).toFixed(2)
  };

  const resp = await axios.post(
      `${baseURL}/${tenant}/workperiod`,
      body,
      {
          headers: {
              Authorization: `Bearer ${token}`,
          },
      }
  );
  return resp;
};

export const deleteWorkPeriod = async (token, tenant, id) => {
  await axios.delete(`${baseURL}/${tenant}/workperiod/${id}`, {
      headers: {
          Authorization: `Bearer ${token}`,
      },
  });
};

export const updateWorkPeriod = async (
  token,
  tenant,
  id,
  employeeAssignmentId,
  dateFrom,
  dateTo,
  workPeriodType,
  weeklyAllocationPercentage,
  mondayAllocationPercentage,
  tuesdayAllocationPercentage,
  wednesdayAllocationPercentage,
  thursdayAllocationPercentage,
  fridayAllocationPercentage,
  detailedAllocationPercentage,
  active
) => {
  const body = {
      id,
      employee_assignment_id: employeeAssignmentId,
      date_from: formatHourDate(dateFrom),
      date_to: formatHourDate(dateTo),
      work_period_type: workPeriodType,
      weekly_allocation_percentage: parseFloat(weeklyAllocationPercentage / 100).toFixed(2),
      monday_allocation_percentage: parseFloat(mondayAllocationPercentage / 100).toFixed(2),
      tuesday_allocation_percentage: parseFloat(tuesdayAllocationPercentage / 100).toFixed(2),
      wednesday_allocation_percentage: parseFloat(wednesdayAllocationPercentage / 100).toFixed(2),
      thursday_allocation_percentage: parseFloat(thursdayAllocationPercentage / 100).toFixed(2),
      friday_allocation_percentage: parseFloat(fridayAllocationPercentage / 100).toFixed(2),
      detailed_allocation_percentage: parseFloat(detailedAllocationPercentage / 100).toFixed(2),
      active: active,
  };

  await axios.put(`${baseURL}/${tenant}/workperiod/${id}`, body, {
      headers: {
          Authorization: `Bearer ${token}`,
      },
  });
};
//#endregion
