import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../UtilFunctions";

const TouchPointAssignmentTable = ({ assignments }) => {

    const navigate = useNavigate();
    const routeChange = (id) => {
        const path = `/assignments/${id}`;
        navigate(path);
    }


    const getTableBody = (assignments) => {
        return assignments.map(arrayData => {
            return (
                <tr className='text-center' onClick={() => routeChange(arrayData.employee_assignment_id)} style={{ cursor: 'pointer' }}>
                    <td>{arrayData.employee_name} {arrayData.employee_lastname}</td>
                    <td>{Math.round(arrayData.allocation_percentage * 100)}%</td>
                    <td>{arrayData.billable ? 'Yes' : 'No'}</td>
                    <td>{formatDate(arrayData.start_date)}</td>
                    <td>{formatDate(arrayData.end_date)}</td>
                </tr>
            )
        });
    }

    if (assignments.length > 0) {
        return (
            <Table striped bordered hover className='mt-2'>
                <thead>
                    <tr className='text-center'>
                        <th>Employee</th>
                        <th>Allocation percentage</th>
                        <th>Billable</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                    </tr>
                </thead>
                <tbody>
                    {getTableBody(assignments)}
                </tbody>
            </Table>
        );
    } else {
        return (
            <p>No assignments found</p>
        );
    }
}

export default TouchPointAssignmentTable;