import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../UtilFunctions";

const EmployeeTable = ({employees}) => {
    const navigate = useNavigate();
    const routeChange = (id) => {
        const path = `/employees/${id}`;
        navigate(path);
    }

    return (
        <div className='w-100'>
            <Table striped bordered hover className='mt-2'>
                <thead>
                    <tr>
                        <th>First name</th>
                        <th>Last name</th>
                        <th>Start date</th>
                    </tr>
                </thead>
                <tbody>
                    {employees.map(arrayData => {
                        return (
                            <tr onClick={() => routeChange(arrayData.id)} style={{cursor: 'pointer'}}>
                                <td>{arrayData.name}</td>
                                <td>{arrayData.lastname}</td>
                                <td>{formatDate(arrayData.start_date)}</td>
                            </tr>
                        )
                    }
                    )}
                </tbody>
            </Table>
        </div>
    );
}

export default EmployeeTable;