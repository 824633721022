import React from "react";
import { EmojiFrown, EmojiSmile, EmojiNeutral } from "react-bootstrap-icons";


const SentimentComponent = (sentiment) => {
    return (
        <div className="d-inline">
            {(() => {
                if (sentiment.sentiment === "HAPPY") {
                    return (
                        <EmojiSmile color="green"/>
                    )
                } else if (sentiment.sentiment === "NEUTRAL") {
                    return (
                        <EmojiNeutral color="orange"/>
                    )
                } else if (sentiment.sentiment === "UNHAPPY") {
                    return (
                        <EmojiFrown color="red"/>
                    )
                }
            })()}
        </div>
    );
}

export default SentimentComponent;
