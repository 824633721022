import React from "react";
import { Form, InputGroup } from "react-bootstrap";

const SearchBar = ({setSearchValue, searchingOn}) => {
    return (
        <InputGroup>
            <Form.Control
                placeholder={'search by ' + searchingOn}
                onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            />
        </InputGroup>
    );
}

export default SearchBar;
