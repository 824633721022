import React from "react";
import { getAllocation } from "../../ApiConnection";
import { Pie } from "react-chartjs-2";
import { Card, Spinner } from "react-bootstrap";
import {Chart, ArcElement, Legend, Tooltip, Title} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {useAuthStore} from '../../stores/AuthStore';
import { useNavigate, useLocation } from "react-router-dom";
import { DateTime } from 'luxon';
import { colors } from '../../UtilFunctions'
Chart.register(ArcElement, Legend, Tooltip, Title, ChartDataLabels);

const pieChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
            label: (ctx) => {
                return `${ctx.dataset.data[ctx.dataIndex]}%`;
            }
        }
      },
      datalabels: {
        color: '#ffffff',
        font: {
          size: 18
        },
        formatter: (value, ctx) => {
            return `${value}%`;
          }
      }
    },
};

const CurrentAllocationChart = ({ setAuthError }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = React.useState('');
  const [chartData, setChartData] = React.useState(null);

    const {token, tenant} = useAuthStore();

    React.useEffect(() => {
        async function getData() {
            try{
              const now = DateTime.utc().startOf('day');
              const data = await getAllocation(token, tenant, {
                aggregationType: 'day',
                startDate: now.toISO(),
                endDate: now.plus({day: 1}).toISO(),
                });
              setChartData({
                  labels: ['Billable', 'Non Billable', 'Free Allocation'],
                  datasets: [
                    {
                      data: getFormattedData(data[0]),
                      backgroundColor: [
                        colors.green,
                        colors.blue,
                        colors.red,
                      ]
                    }
                  ]
                })
            } catch(e) {
              if (e?.response?.status === 401 || e?.response?.status === 403) setAuthError(e);
              else setError(e);
            }
        }
        getData();
    }, []);

    function getFormattedData (data) {
        if (data === undefined) return [0, 0, 0, 0];
        const freeAllocation = 100 - data.non_billable_allocation - data.billable_allocation;
        return [data.billable_allocation, data.non_billable_allocation, freeAllocation];
    }

    function Chart(){
        return(
          <div style={{ display: 'flex', justifyContent: 'center' }}>
              {error ? <p><b>Error:</b> {error.response.data.message}</p> : !chartData ? <Spinner className='mt-2' animation="border" /> : <Pie data={chartData} options={pieChartOptions}/>}
          </div>
        );
    }

    return (
      <Card className='h-100'>
        <Card.Header><b>Current Allocation (today)</b></Card.Header>
        <Card.Body>
          <div className="d-flex justify-content-center align-items-center">
            <Chart></Chart>
          </div>
        </Card.Body>
      </Card>
    );
  }
  
  export default CurrentAllocationChart;
  