import { useParams, Link, useNavigate } from 'react-router-dom'
import { Breadcrumb, Form, Alert, Button } from 'react-bootstrap';
import React from 'react';
import { updateCustomer, getCustomerDetail } from '../../ApiConnection'
import { useAuthStore } from '../../stores/AuthStore';
import { getHelmet } from '../../UtilFunctions';

const EditCustomer = () => {
    const { token, tenant } = useAuthStore();
    const { id } = useParams();
    const [customer, setCustomer] = React.useState("");
    const [name, setName] = React.useState("");
    const [trackContactActivity, setTrackContactActivity] = React.useState(false);
    const [active, setActive] = React.useState(false);
    const [error, setError] = React.useState();
    

    const navigate = useNavigate();
    const routeChange = (id) => {
        const path = `/customers/${id}`;
        navigate(path);
    }

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Customer Edit', 'Make edits on customer', true);
    }

    React.useEffect(() => {
        async function getData() {
            const customer = await getCustomerDetail(token, tenant, id);
            setName(customer.name);
            setTrackContactActivity(customer.track_contact_activity === 1 ? true : false);
            setActive(customer.active === 1 ? true : false);
            setCustomer(customer);
        }
        getData()
    }, []);

    const handleSubmit = async event => {
        event.preventDefault();

        try {
            await updateCustomer(token, tenant, id, name, trackContactActivity, active)
            routeChange(id);
        } catch (error) {
            setError(error.response.data)
        }
    }

    return (
        <div>
            <Helmet />
            <Breadcrumb className="mt-3">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/customers" }} >Customers</Breadcrumb.Item>
                <Breadcrumb.Item active>Edit Customer</Breadcrumb.Item>
            </Breadcrumb>
            <h5>Edit Customer</h5>
            {(customer &&
                <form onSubmit={handleSubmit} >
                    <Form.Group className="mb-3">
                        <Form.Label>Name <span className='text-danger'>*</span></Form.Label>
                        <Form.Control type='text' onChange={e => setName(e.target.value.trim() === '' ? e.target.value.trim() : e.target.value.trimStart())} value={name} required />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Track contact activity</Form.Label>
                        <Form.Switch onChange={e => setTrackContactActivity(!trackContactActivity)} checked={trackContactActivity} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Active</Form.Label>
                        <Form.Switch onChange={e => setActive(!active)} checked={active} />
                    </Form.Group>
                    {error && 
                        <Alert className={`mb-3 ${error ? 'd-none': ''}`} variant="danger">
                            {error}
                        </Alert>
                    }
                    <Button variant="dark" type="submit">Edit employee assignment</Button>
                </form>
            )}
        </div>
    );
}

export default EditCustomer;
