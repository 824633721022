import { Breadcrumb, Button, Modal, Row, Col } from 'react-bootstrap'
import { useNavigate, Link, useParams } from 'react-router-dom'
import { getContact, deleteContact } from '../../ApiConnection'
import { calculateTimeBetween, getHelmet } from '../../UtilFunctions';
import { useAuthStore } from '../../stores/AuthStore';
import { useEffect, useState } from 'react';

const ContactDetail = () => {
    const { token, tenant } = useAuthStore();
    const { id } = useParams();
    const [contact, setContact] = useState(null);
    const [show, setShow] = useState(false);

    const navigate = useNavigate();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleDelete = async (id) => {
        await deleteContact(token, tenant, id);
        navigate('/contacts');
    }

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Contact Details', 'Details of a contact', true);
    }

    useEffect(() => {
        async function getData() {
            setContact(await getContact(token, tenant, id));
        }
        getData()
    }, []);

    if (!contact) return null;

    return (
        <div>
            <Helmet />
            <Breadcrumb className="mt-3">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/contacts" }} >Contacts</Breadcrumb.Item>
                <Breadcrumb.Item active>Contact Details</Breadcrumb.Item>
            </Breadcrumb>
            <Row>
                <Col>
                    <h5>Contact details</h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>First name</small>
                    <p>{contact.name}</p>
                </Col>
                <Col>
                    <small className='text-muted'>Last name</small>
                    <p>{contact.lastname}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>Email</small>

                    <p>{contact.email}</p>
                </Col>
                <Col>
                    <small className='text-muted'>Active</small>

                    <p>{contact.active ? 'Yes' : 'No'}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>Created</small>
                    <p>{calculateTimeBetween(contact.created_at)}</p>
                </Col>
                <Col>
                    <small className='text-muted'>Created by</small>
                    <p>{contact.created_by}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>Last modified</small>

                    <p>{calculateTimeBetween(contact.modified_at)}</p>
                </Col>
                <Col>
                    <small className='text-muted'>Last modified by</small>
                    <p>{contact.last_modified_by}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button onClick={() => navigate(`/contacts/edit/${contact.id}`)} variant="dark">Edit</Button>{' '}
                    <Button onClick={handleShow} variant="dark">Remove</Button>
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Contact</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure, you want to delete this Contact!</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="dark" onClick={() => { handleDelete(contact.id) }}>
                        Delete contact
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ContactDetail;