import React, { useState } from "react";
import { Button, Form, Container, Spinner, Card, ListGroup } from "react-bootstrap";
import { addComment, getCommentsOfResource } from "../../ApiConnection";
import { useAuthStore } from "../../stores/AuthStore";
import Comment from "./Comment";
import YourComment from "./YourComment";
import { observer } from "mobx-react";

const CommentSection = ({ resource }) => {
  const { token, tenant, userIdentifier } = useAuthStore();
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchComments = async () => {
    try {
      setComments(await getCommentsOfResource(token, tenant, resource));
      setLoading(false);
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  };

  const handleCreate = async () => {
    await addComment(token, tenant, resource, newComment);
    setLoading(true);
    setNewComment('');
    await fetchComments();
  };

  React.useEffect(() => {
    fetchComments();
  }, []);

  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner className="mt-2" animation="border" />
      </div>
    );

  return (
    <Card>
      <Card.Body>
        <Card.Title>Comments</Card.Title>
      </Card.Body>
      <ListGroup className="list-group-flush">
        {comments.map((arrayData) =>
          userIdentifier === arrayData.created_by ? (
            <YourComment comment={arrayData} fetch={fetchComments} />) :
            (<Comment comment={arrayData} />)
        )}
      </ListGroup>
      <Card.Body>
        <div className="w-100 d-flex justify-content-center align-items-center">
          <Form.Text
            className="w-75 rounded text-dark"
            placeholder="Add a comment"
            as="textarea"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
          />
          <Button variant="dark" className="ms-3 w-25" onClick={() => handleCreate()}>
            Comment
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default observer(CommentSection);
