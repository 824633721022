import React, { useState } from "react";
import { Table,Spinner, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getLatestNegativeTouchPoint } from "../../ApiConnection";
import {useAuthStore} from '../../stores/AuthStore';
import { formatDate } from '../../UtilFunctions';

const LatestNegativeTouchPointTable = ({ setAuthError }) => {
  const { token, tenant } = useAuthStore();
  const amount = 5;
  const [touchPoints, setTouchPoints] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    async function getData() {
      try {
        setTouchPoints(
          await getLatestNegativeTouchPoint(token, tenant, amount)
        );
      } catch (e) {
        if (e?.response?.status === 401 || e?.response?.status === 403) setAuthError(e);
        else setError(e);
      }
    }
    getData();
  }, [amount]);

  return (
    <Card className="h-100">
      <Card.Header>
        <b>Latest negative touchpoint</b>
      </Card.Header>
      <Card.Body>
        <div>
          {error ? (
            <p>
              <b>Error:</b> {error.response.data.message}
            </p>
          ) : !touchPoints ? (
            <Spinner className="mt-2" animation="border" />
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Customer</th>
                  <th>Project</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {touchPoints.map((arrayData) => {
                  return (
                    <tr
                      onClick={() => navigate(`/touchpoints/${arrayData.id}`)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{arrayData.customer}</td>
                      <td>{arrayData.project}</td>
                      <td>{formatDate(arrayData.date)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </div>
      </Card.Body>
    </Card>
  );
}

export default LatestNegativeTouchPointTable;