import { Breadcrumb, Button, Modal, Row, Col, Tabs, Tab, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useNavigate, Link, useParams } from 'react-router-dom'
import React from "react";
import { deleteproject, getprojectDetail } from '../../ApiConnection'
import { formatDate, calculateTimeBetween, getHelmet } from '../../UtilFunctions';
import EmployeeAssignmentTableCompact from '../assignment/EmployeeAssignmentTableCompact';
import { useAuthStore } from '../../stores/AuthStore';
import { DateTime } from 'luxon';
import { CostIncomeBarChart, IncomeBarChart } from '../charts';
import { observer } from 'mobx-react';

const ProjectDetail = () => {
    const { token, tenant, roles } = useAuthStore();
    const { id } = useParams();
    const [project, setProject] = React.useState(null);
    const [show, setShow] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleDelete = async (id) => {
        await deleteproject(token, tenant,id);
        const path = `/projects`;
        navigate(path);
    }

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Project Details', 'Details of a project', true);
    }

    const navigate = useNavigate();
    const routeChange = (id) => {
        const path = `/projects/edit/${id}`;
        navigate(path);
    }

    const routeChangeAddTouchPoint = (uuid) => {
        const path = `/touchpoints/create/${uuid}`;
        navigate(path);
    }

    React.useEffect(() => {
        async function getData() {
            setProject(await getprojectDetail(token, tenant, id));
        }
        getData()
    }, []);

    if (!project) return null;

    return (
        <div>
            <Helmet />
            <Breadcrumb className="mt-3">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/projects" }} >Projects</Breadcrumb.Item>
                <Breadcrumb.Item active>Project Details</Breadcrumb.Item>
            </Breadcrumb>
            <Row>
                <Col>
                    <h5>Project details</h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>Project name</small>
                    <p>{project.name}</p>
                </Col>
                <Col>
                    <small className='text-muted'>Customer</small>
                    <p><Link to={`/customers/${project.customer}`}>{project.customer_name}</Link></p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>Description</small>
                    <p>{project.description}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>Start date</small>
                    <p>{formatDate(project.start_date)}</p>
                </Col>
                <Col>
                    <small className='text-muted'>End date</small>
                    <p>{formatDate(project.end_date)}</p>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col>
                    <small className='text-muted'>Internal responsible</small>
                    <br />
                    <OverlayTrigger
                    delay={ { hide: 450, show: 300 } }
                    overlay={(props) => (
                        <Tooltip {...props}>
                            {project.internal_responsible.email}
                        </Tooltip>
                    )} >
                        <p className='d-inline'><Link to={`/contacts/${project.internal_responsible.id}`}>{project.internal_responsible.name} {project.internal_responsible.lastname}</Link></p>
                    </OverlayTrigger>
                </Col>
                <Col>
                    <small className='text-muted'>Customer responsible</small>
                    <br />
                    <OverlayTrigger
                    delay={ { hide: 450, show: 300 } }
                    overlay={(props) => (
                        <Tooltip {...props}>
                            {project.customer_responsible.email}
                        </Tooltip>
                    )} >
                        <p className='d-inline'><Link to={`/contacts/${project.customer_responsible.id}`}>{project.customer_responsible.name} {project.customer_responsible.lastname}</Link></p>
                    </OverlayTrigger>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>Active</small>
                    <p>{project.active ? 'Yes' : 'No'}</p>
                </Col>
                <Col>
                    <small className='text-muted'>Account manager</small>
                    <p>{project.account_manager}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>Created</small>
                    <p>{calculateTimeBetween(project.created_at)}</p>
                </Col>
                <Col>
                    <small className='text-muted'>Created by</small>
                    <p>{project.created_by}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>Last modified</small>
                    <p>{calculateTimeBetween(project.modified_at)}</p>
                </Col>
                <Col>
                    <small className='text-muted'>Last modified by</small>
                    <p>{project.last_modified_by}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button onClick={() => routeChange(project.id)} variant="dark">Edit</Button>{' '}
                    <Button onClick={handleShow} variant="dark">Remove</Button>{' '}
                    <Button onClick={() => routeChangeAddTouchPoint(project.id)} variant="dark">Add Touch point</Button>
                </Col>
            </Row>
            <Tabs defaultActiveKey={roles.includes('Sales') ? 'costIncomeChart' : 'assignmentshistory'} className="mb-3 mt-2" id="uncontrolled-tab-example">
                {roles.includes('Financial') ?
                    <Tab eventKey="costIncomeChart" title="Cost/Income visualization">
                        <h5>Cost-Income</h5>
                        <CostIncomeBarChart on='project' refresh />
                    </Tab>
                : roles.includes('Sales') ?
                    <Tab eventKey="costIncomeChart" title="Cost/Income visualization">
                        <h5>Income</h5>
                        <IncomeBarChart on='project' refresh />
                    </Tab>
                : null
                }
                <Tab eventKey="assignmentshistory" title="Assignments history">
                    <h5>Assignments history</h5>
                    <EmployeeAssignmentTableCompact assignments={project.employee_assignments.filter(emp => DateTime.fromISO(emp.end_date) < DateTime.now()).map(emp => ({ ...emp, customer_name: project.customer_name, project_name: project.name }))} companyView={false} />
                </Tab>
                <Tab eventKey="assignments" title="Current assignments">
                <div className='d-flex'>
                        <h5>Current assignments</h5>
                        <Button className="w-25 ms-auto" onClick={() => navigate(`/assignments/create`, { state: { project: id, project_name: project.name, customer: project.customer, customer_name: project.customer_name } })} variant="dark">Add Assignment</Button>
                    </div>
                    <EmployeeAssignmentTableCompact assignments={project.employee_assignments.filter(emp => DateTime.fromISO(emp.end_date) >= DateTime.now() && DateTime.fromISO(emp.start_date) <= DateTime.now()).map(emp => ({ ...emp, customer_name: project.customer_name, project_name: project.name }))} companyView={false} />
                </Tab>
                <Tab eventKey="futureassignments" title="Future assignments">
                    <h5>Future assignments</h5>
                    <EmployeeAssignmentTableCompact assignments={project.employee_assignments.filter(emp => DateTime.fromISO(emp.start_date) > DateTime.now()).map(emp => ({ ...emp, customer_name: project.customer_name, project_name: project.name }))} companyView={false} />
                </Tab>
            </Tabs>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Project</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure, you want to delete this Project!</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="dark" onClick={() => { handleDelete(project.id) }}>
                        Delete Project
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default observer(ProjectDetail);
