import React from "react";
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import { DateTime } from 'luxon'
import { useNavigate } from "react-router-dom";

const AssignmentVisualization = ({ allAssignments }) => {
    const navigate = useNavigate();
    const colors = {
        billable: "#75f542",
        nonbillable: "#42c2f5"
    }

    const routeChange = (id) => {
        const path = `/assignments/${id}`;
        navigate(path);
    }

    const [assignments, setAssignments] = React.useState([]);

    const onClickAssignment = eventClickInfo => {
        routeChange(eventClickInfo.event.extendedProps.id);
    }

    React.useEffect(() => {
        const assignments = [];
        allAssignments.map(assignment => {
            assignments.push({
                title: `${assignment.project_name} - ${(assignment.allocation_percentage * 100).toFixed(0)}% Allocation`,
                start: DateTime.fromISO(assignment.start_date).toFormat('yyyy-MM-dd'),
                end: DateTime.fromISO(assignment.end_date).toFormat('yyyy-MM-dd'),
                backgroundColor: ((assignment.billable) ? colors.billable : colors.nonbillable),
                borderColor: ((assignment.billable) ? colors.billable : colors.nonbillable),
                textColor: "#332D2D",
                extendedProps: {
                    id: assignment.id
                  },
            })
        });
        setAssignments(assignments);
    }, []);

    return (
        <div>
            <FullCalendar
                contentHeight={700}
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                weekends={false}
                events={assignments}
                eventClick={onClickAssignment}
            />
        </div>
    );
}

export default AssignmentVisualization;
