import React, { useState } from "react";
import { Card, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getStaleTouchPoint } from "../../ApiConnection";
import {useAuthStore} from '../../stores/AuthStore';
import SentimentComponent from '../touchpoint/SentimentComponent';
import { Spinner } from 'react-bootstrap';

const StaleTouchPointTable = ({ setAuthError }) => {
  const { token, tenant } = useAuthStore();
  const amount = 5;
  const [touchPoints, setTouchPoints] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    async function getData() {
      try {
        setTouchPoints(await getStaleTouchPoint(token, tenant, amount));
      } catch (e) {
        if (e?.response?.status === 401 || e?.response?.status === 403) setAuthError(e);
        else setError(e);
      }
    }
    getData();
  }, [amount]);

  return (
    <Card className="h-100">
      <Card.Header>
        <b>Most stale touchpoints</b>
      </Card.Header>
      <Card.Body>
        <div>
          {error ? (
            <p>
              <b>Error:</b> {error.response.data.message}
            </p>
          ) : !touchPoints ? (
            <Spinner className="mt-2" animation="border" />
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Customer</th>
                  <th>Project</th>
                  <th>Days ago</th>
                  <th>Sentiment</th>
                </tr>
              </thead>
              <tbody>
                {touchPoints.map((arrayData) => {
                  return (
                    <tr
                      onClick={() => navigate(`/employees/${arrayData.id}`)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{arrayData.customer}</td>
                      <td>{arrayData.project_name}</td>
                      <td>{arrayData.days} days</td>
                      <td>
                        <SentimentComponent sentiment={arrayData.sentiment} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </div>
      </Card.Body>
    </Card>
  );
}

export default StaleTouchPointTable;