import React from "react";
import { useNavigate } from 'react-router-dom'
import { getAPIVersion } from "../ApiConnection";
import { useAuthStore } from '../stores/AuthStore'
import { getHelmet } from "../UtilFunctions";

const baseURL = `${process.env.REACT_APP_API_URL}/version`;


const Contact = () => {
    const [version, setVersion] = React.useState(null);

    const navigate = useNavigate();
    const routeChange = () => {
        let path = `/Unauthorized/`;
        navigate(path);
    }

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Contact', 'Contact page', true);
    }

    React.useEffect(() => {
        async function getData() {
            try {
                setVersion(await getAPIVersion());
            } catch(e) {
                routeChange()
            }
        }
        getData()
    }, []);

    return (
        <div>
            <Helmet />
            <h1>API Version Page</h1>
            <p>{version}</p>
        </div>
    );
}

export default Contact;
