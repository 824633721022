import React from "react";
import { observer } from "mobx-react";
import { CurrencyEuro } from "react-bootstrap-icons";
import { useAuthStore } from "../stores/AuthStore";
import { checkFinance } from "../ApiConnection";

const FinanceAlarm = () => {
    const { setShowFinanceAlarm, showFinanceAlarm, setCheckFinanceData, setMonthsBelowFinancePercentage, monthsBelowFinancePercentage, tenant, isLoggedIn, token, tenantFinanceAlarmPercentageSetting, tenantFinanceAlarmMonthsSetting } = useAuthStore();

    React.useEffect( () => {
        async function fetchCheckFinance() {
            try {
                const result = await checkFinance(token, tenant, tenantFinanceAlarmPercentageSetting, tenantFinanceAlarmMonthsSetting);
                setMonthsBelowFinancePercentage(result.reduce((acc, cur) => acc + !cur, 0));
                setCheckFinanceData(result);
            } catch (e) {
                console.log("error");
                setMonthsBelowFinancePercentage(0);
                setCheckFinanceData({});
            }
        }
        if (isLoggedIn) { fetchCheckFinance(); }
    }, [isLoggedIn, tenantFinanceAlarmPercentageSetting, tenantFinanceAlarmMonthsSetting]);


    const changeShow = () => {
        setShowFinanceAlarm(!showFinanceAlarm);
    }

    return (
        <div style={{ cursor: "pointer" }}>
            {(monthsBelowFinancePercentage > 0 &&
                <span className="position-relative me-3" onClick={() => changeShow()}><CurrencyEuro className="fs-5" color="red" /> <span className="position-absolute top-0 start-100 translate-middle badge bg-danger rounded-circle badge-font-size">{monthsBelowFinancePercentage}</span></span>
            )}
        </div>
    );
}

export default observer(FinanceAlarm);
