import { observer } from 'mobx-react';
import React from 'react';
import { Navigate } from "react-router-dom";
import { useAuthStore } from '../../stores/AuthStore';

const CheckAuth = ({ children, path = '/', allowedRoles }) => {
    const { isLoggedIn, roles } = useAuthStore();

    if (isLoggedIn && allowedRoles?.some(allowedRole => roles.includes(allowedRole))) return children;
    else if (isLoggedIn) return <Navigate to="/NotAuthenticated/403" state={ { prevLocation: path } } />;
    return <Navigate to="/NotAuthenticated/401" state={ { prevLocation: path } } />;
}

export default observer(CheckAuth);