import { DateTime } from "luxon";
import { useState } from "react";
import { Alert, Breadcrumb, Button, Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { addEmployeeAssignmentIncome } from "../../ApiConnection";
import { useAuthStore } from "../../stores/AuthStore";
import { getHelmet } from "../../UtilFunctions";


const CreateEmployeeAssignmentIncome = () => {
    const { id } = useParams();
    const { token, tenant } = useAuthStore();
    const [from, setFrom] = useState(DateTime.now().toJSDate());
    const [amount, setAmount] = useState(0);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    const handleSubmit = async event => {
        event.preventDefault();

        try {
            await addEmployeeAssignmentIncome(token, tenant, id, from, amount);
            navigate(`/assignments/${id}`);
        } catch (error) {
            setError(error.response.data);
        }
    };

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Employee Assignment Income Creation', 'Creation of employee assignment income', true);
    };

    return (
        <div>
            <Helmet />
            <Breadcrumb className="mt-3">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/assignments" }} >Assignments</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/assignments/${id}` }}>Employee Details</Breadcrumb.Item>
                <Breadcrumb.Item active>Create Employee Assignment Income</Breadcrumb.Item>
            </Breadcrumb>
            <Form onSubmit={handleSubmit} >
                <Form.Group className='mb-3'>
                    <Form.Label>From <span className='text-danger'>*</span></Form.Label>
                    <Form.Control onChange={e => setFrom(new Date(e.target.value))} type="date" value={DateTime.fromJSDate(from).toFormat('yyyy-MM-dd')} required/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Amount <span className='text-danger'>*</span></Form.Label>
                    <Form.Control type="number" onChange={e => setAmount(e.target.value.trim() === '' && !isNaN(e.target.value) ? e.target.value.trim() : e.target.value.trimStart())} value={amount} required />
                </Form.Group>
                {(error &&
                    <Alert className='mb-3' variant="danger">
                        {error}
                    </Alert>)}
                <Button variant="dark" type="submit">Add employee assignment income</Button>
            </Form>
        </div>
    )
}

export default CreateEmployeeAssignmentIncome;