import { Table, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../UtilFunctions";

const ProjectTableCompact = ({ projects }) => {

    const navigate = useNavigate();
    const routeChange = (id) => {
        const path = `/projects/${id}`;
        navigate(path);
    }

    const handleEdit = (id, e) => {
        e.stopPropagation();
        navigate(`/projects/edit/${id}`)
    }

    if (projects.length > 0) {
    return (
        <Table striped bordered hover className='mt-2'>
            <thead>
                <tr className='text-center'>
                    <th>Customer</th>
                    <th>Name</th>
                    <th>Start date</th>
                    <th>End date</th>
                    <th>Internal responsible</th>
                    <th>Customer responsible</th>
                    <th>Edit</th>
                </tr>
            </thead>
            <tbody>
                {projects.map(arrayData => {
                    return (
                        <tr className='text-center' onClick={() => routeChange(arrayData.project_id)} style={{ cursor: 'pointer' }}>

                            <td>{arrayData.customer_name}</td>
                            <td>{arrayData.project_name}</td>
                            <td>{formatDate(arrayData.project_start_date)}</td>
                            <td>{formatDate(arrayData.project_end_date)}</td>
                            <td>{arrayData.project_internal_responsible.name} {arrayData.project_internal_responsible.lastname}</td>
                            <td>{arrayData.project_customer_responsible.name} {arrayData.project_customer_responsible.lastname}</td>
                            <td className='d-flex align-items-center justify-content-center'><Button onClick={e => handleEdit(arrayData.project_id, e)} variant="dark">Edit</Button></td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )} else {
        return(
        <p className="mt-2">No Projects found</p>
        )
    }
}

export default ProjectTableCompact;