import { useEffect, useState } from "react";
import { Breadcrumb, Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { deleteUser, getTenantsFromUser, getUser } from "../../../ApiConnection";
import { useAuthStore } from "../../../stores/AuthStore";
import { formatDate, getHelmet } from "../../../UtilFunctions";
import TenantTable from "../tenant/TenantTable";
import UserAttributeTable from "./UserAttributeTable";

const UserDetail = () => {
  const { token } = useAuthStore();
  let { username } = useParams();
  const [user, setUser] = useState(null);
  const [tenants, setTenants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const handleDelete = async (username) => {
    await deleteUser(token, username);
    navigate("/admin-panel/users");
  };

  const Helmet = () => {
    return getHelmet('Cloudway Accounts - User Details', 'Details of a user', true);
  }

  const getData = async () => {
    try {
      setUser(await getUser(token, username));
      setTenants(await getTenantsFromUser(token, username));
      setLoading(false);
    } catch (e) {
      navigate(`/NotAuthenticated/${e.response.status}`, {
        state: { prevLocation: location.pathname },
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (!loading)
    <div className="d-flex justify-content-center align-items-center">
      <Spinner className="mt-2" animation="border"></Spinner>
    </div>;

  if (!user) return null;

  return (
    <div>
      <Helmet />
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin-panel/users" }}>
          Users
        </Breadcrumb.Item>
        <Breadcrumb.Item active>User Details</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col>
          <h5>User details</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <small className="text-muted">Username</small>
          <p>{user.attributes.find(attr => attr.Name == 'name')?.Value || user.username}</p>
        </Col>
        <Col>
          <small className="text-muted">User status</small>
          <p>{user.userStatus}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <small className="text-muted">Created at</small>
          <p>{formatDate(user.created_at)}</p>
        </Col>
        <Col>
          <small className="text-muted">Modified at</small>
          <p>{formatDate(user.modified_at)}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button className="me-2" onClick={() => navigate(`/admin-panel/users/${username}/add-roles`)} variant="dark">
            Add to tenant
          </Button> 
          <Button onClick={() => setShow(true)} variant="dark">
            Remove
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <h5>Tenants</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <TenantTable tenants={tenants.sort((a, b) => a.name - b.name)} showRoles={true} username={username} />
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Attributes</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <UserAttributeTable attributes={user.attributes} />
        </Col>
      </Row>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure, you want to delete this User!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button
            variant="dark"
            onClick={() => {
              handleDelete(user.username);
            }}
          >
            Delete user
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserDetail;
