import React, { useState } from "react";
import { Dropdown } from "react-bootstrap"
import { observer } from "mobx-react";
import { useAuthStore } from "../stores/AuthStore";
import { useCookies } from 'react-cookie';
import { DateTime } from "luxon";
import { getRoles } from "../ApiConnection";
import { getTenantSettings } from "../ApiConnection";

const NavigationBarTenant = () => {
    const [cookies, setCookie] = useCookies(['last_tenant']);
    const { token, tenant, setTenant, actionnableTenants, setRoles, setTenantAllocationAlarmThresholdSetting, setTenantAllocationAlarmMonthsSetting, setTenantFinanceAlarmPercentageSetting, setTenantFinanceAlarmMonthsSetting } = useAuthStore();
    const [selectedTenant, setSelectedTenant] = useState(null);

    const selectTenant = async (tenant) => {
        setCookie('last_tenant', tenant.id, { path: '/', expires: DateTime.now().plus({ month: 1 }).toJSDate() });
        setTenant(tenant.id);

        setRoles((await getRoles(token, tenant.id)).map(role => role.role_name));
        const tenantSettings = await getTenantSettings(token, tenant.id);
        setTenantAllocationAlarmThresholdSetting(tenantSettings.allocation_alarm_threshold);
        setTenantAllocationAlarmMonthsSetting(tenantSettings.allocaion_alarm_months);
        setTenantFinanceAlarmPercentageSetting(tenantSettings.finance_alarm_finance);
        setTenantFinanceAlarmMonthsSetting(tenantSettings.finance_alarm_months);
        window.location.href = '/'; // replace with navigate (useEffects need to include tenant in dep array)
    }

    React.useEffect(() => {
        if (tenant) {
            setSelectedTenant(actionnableTenants.find(obj => {
                return obj.id === tenant;
            }));
        }
    }, [tenant])

    return (
        <div className="ms-auto d-flex justify-content-center align-items-center" >
            <Dropdown>
                <Dropdown.Toggle variant="dark" id="tenant-dropdown">
                    {selectedTenant ? selectedTenant.name : "Tenant"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {actionnableTenants.map(tenant => (
                        <Dropdown.Item onClick={() => selectTenant(tenant)}>{tenant.name}</Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div >
    );
}

export default observer(NavigationBarTenant);
