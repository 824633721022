import { useEffect } from "react";
import { Dropdown, Navbar, Nav, NavLink } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAuthStore } from "../../stores/AuthStore";
import CloudwayLogo from '../../cloudway_logo_small.svg';

const NavigationBarAdmin = () => {
  const { currentUser } = useAuthStore();

  const navigate = useNavigate();
  const logout = () =>
    window.location.replace(
      `${process.env.REACT_APP_COGNITO_URL}/logout?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&logout_uri=${process.env.REACT_APP_COGNITO_LOGOUT_URI}`
    );

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <Navbar
        className="w-100"
        collapseOnSelect
        expand="sm"
        bg="dark"
        variant="dark"
      >
        <Navbar.Brand className="ms-2" as={Link} to="/">
        <img width="70" height="50" alt="Cloudway logo" src={CloudwayLogo} />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="navbarScroll"
          data-bs-target="#navbarScroll"
        />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="container-fluid">
            <NavLink eventKey="1" as={Link} to="/admin-panel/tenants">
              Tenants
            </NavLink>
            <NavLink eventKey="2" as={Link} to="/admin-panel/users">
              Users
            </NavLink>
            <div className="ms-auto d-flex justify-content-center align-items-center">
              {currentUser ? (
                <Dropdown>
                  <Dropdown.Toggle variant="dark" id="dropdown-basic">
                    {currentUser}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => logout()}>
                      Logout
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => navigate("/")}>
                      Exit Admin
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Navbar.Text className="ms-auto">
                  <a
                    href={`${process.env.REACT_APP_COGNITO_URL}/login?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&response_type=code&scope=email+openid+phone+profile&redirect_uri=${process.env.REACT_APP_COGNITO_LOGIN_URI}`}
                  >
                    Login
                  </a>
                </Navbar.Text>
              )}
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default NavigationBarAdmin;
