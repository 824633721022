import { DateTime } from 'luxon';
import { useState } from 'react';
import { Form, Button, Breadcrumb, Alert } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { addContact } from '../../ApiConnection';
import { useAuthStore } from '../../stores/AuthStore';
import { getHelmet } from '../../UtilFunctions';

const CreateContact = () => {
    const { token, tenant } = useAuthStore();
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState("");
    const [error, setError] = useState();

    const navigate = useNavigate();

    const handleSubmit = async event => {
        event.preventDefault();

        try {
            const result = await addContact(token, tenant, name, lastname, email);
            navigate(`/contacts/${result.id}`);
        } catch (error) {
            setError(error.response.data);
        }
    };

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Contact Creation', 'Creation of contact', true);
    }

    return (
        <div>
            <Helmet />
            <Breadcrumb className="mt-3">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/contacts" }} >Contacts</Breadcrumb.Item>
                <Breadcrumb.Item active>Create Contact</Breadcrumb.Item>
            </Breadcrumb>
            <form onSubmit={handleSubmit} >
                <Form.Group className="mb-3">
                    <Form.Label>First name <span className='text-danger'>*</span></Form.Label>
                    <Form.Control onChange={e => setName(e.target.value.trim() === '' ? e.target.value.trim() : e.target.value.trimStart())} value={name} required />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Last name <span className='text-danger'>*</span></Form.Label>
                    <Form.Control onChange={e => setLastname(e.target.value.trim() === '' ? e.target.value.trim() : e.target.value.trimStart())} value={lastname} required />
                </Form.Group>
                <Form.Group className='mb-3'>
                    <Form.Label>Email <span className='text-danger'>*</span></Form.Label>
                    <Form.Control onChange={e => setEmail(e.target.value.trim() === '' ? e.target.value.trim() : e.target.value.trimStart())} value={email} required />
                </Form.Group>
                {(error &&
                    <Alert className='mb-3' variant="danger">
                        {error}
                    </Alert>)}
                <Button variant="dark" type="submit">Add contact</Button>
            </form>
        </div>
    );
}

export default CreateContact;