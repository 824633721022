import {Breadcrumb, Button, Modal, Row, Col} from 'react-bootstrap'
import {useNavigate, Link, useParams} from 'react-router-dom'
import React, {useState} from "react";
import {getWorkPeriodDetail, deleteWorkPeriod} from '../../ApiConnection'
import {formatDate, getHelmet} from '../../UtilFunctions';
import {useAuthStore} from '../../stores/AuthStore';
import {observer} from 'mobx-react';

const WorkPeriodDetail = () => {
    const {token, tenant} = useAuthStore();
    const {id, workperiodid} = useParams();
    const [workPeriod, setWorkPeriod] = useState(null);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleDelete = async () => {
        await deleteWorkPeriod(token, tenant, workperiodid);
        const path = `/assignments/${id}`;
        navigate(path);
    }

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Work Period Details', 'Details of an work period', true);
    }

    const navigate = useNavigate();
    const routeChange = () => {
        const path = `/assignments/${id}/workperiod/edit/${workperiodid}`;
        navigate(path);
    }

    React.useEffect(() => {
        async function getData() {
            setWorkPeriod(await getWorkPeriodDetail(token, tenant, workperiodid));
        }
        getData()
    }, []);

    if (!workPeriod) return null;

    return (
        <div>
            <Helmet/>
            <Breadcrumb className="mt-3">
                <Breadcrumb.Item linkAs={Link} linkProps={{to: "/"}}>Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{to: "/assignments"}}>Assignments</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{to: `/assignments/${id}`}}>Employee Assignment
                    Details</Breadcrumb.Item>
                <Breadcrumb.Item active>Work Period Details</Breadcrumb.Item>
            </Breadcrumb>
            <Row>
                <Col>
                    <h5>Work period details</h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>Type</small>
                    <p>{workPeriod.work_period_type[0] + workPeriod.work_period_type.toLowerCase().slice(1)}</p>
                </Col>
            </Row>
            <Row>
                <Col xs="2">
                    <small className='text-muted'>Date From</small>
                    <p>{formatDate(workPeriod.date_from)}</p>
                </Col>
                <Col xs="2">
                    <small className='text-muted'>Date To</small>
                    <p>{formatDate(workPeriod.date_to)}</p>
                </Col>
                <Col>
                    <small className='text-muted'>Active</small>
                    <p>{workPeriod.active ? 'Yes' : 'No'}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>Allocation percentages</small>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>Weekly</small>
                    <p>{workPeriod.weekly_allocation_percentage * 100}%</p>
                </Col>
                <Col>
                    <small className='text-muted'>Monday</small>
                    <p>{workPeriod.monday_allocation_percentage * 100}%</p>
                </Col>
                <Col>
                    <small className='text-muted'>Tuesday</small>
                    <p>{workPeriod.tuesday_allocation_percentage * 100}%</p>
                </Col>
                <Col>
                    <small className='text-muted'>Wednesday</small>
                    <p>{workPeriod.wednesday_allocation_percentage * 100}%</p>
                </Col>
                <Col>
                    <small className='text-muted'>Thursday</small>
                    <p>{workPeriod.thursday_allocation_percentage * 100}%</p>
                </Col>
                <Col>
                    <small className='text-muted'>Friday</small>
                    <p>{workPeriod.friday_allocation_percentage * 100}%</p>
                </Col>
                <Col>
                    <small className='text-muted'>Detailed</small>
                    <p>{workPeriod.detailed_allocation_percentage * 100}%</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button onClick={() => routeChange()} variant="dark">Edit</Button>{' '}
                    <Button onClick={handleShow} variant="dark">Remove</Button>
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Work Period</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure, you want to delete this Work Period?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="dark" onClick={() => {
                        handleDelete(workperiodid)
                    }}>
                        Delete work period
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default observer(WorkPeriodDetail);
