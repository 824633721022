import { observer } from "mobx-react";
import { useAuthStore } from "../../stores/AuthStore";
import { useCookies } from 'react-cookie';
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getProfile, getFullRoles, getTenantSettings } from "../../ApiConnection";
import { checkIfCookieLastTenantInCurrentTenants, getHelmet } from "../../UtilFunctions";
import { SquareLoader  } from 'react-spinners';


const Login = observer(() => {
    const [cookies, setCookie] = useCookies(['last_tenant']);
    const [loading, setLoading] = useState(false);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const code = params.get('code');
    const { setAuthToken, setRefreshToken, setCurrentUser, setTenants, setTenant, setIsLoggedIn, setUserIdentifier, setRoles, setTenantAllocationAlarmThresholdSetting, setTenantAllocationAlarmMonthsSetting, setTenantFinanceAlarmPercentageSetting, setTenantFinanceAlarmMonthsSetting } = useAuthStore();
    const cognito_url =  process.env.REACT_APP_COGNITO_URL;

    const config = {
        headers: {
            "Content-Type": 'application/x-www-form-urlencoded',
        }
    }

    const navigate = useNavigate();

    const fetchTenantSettings = async (token, tenant) => {
        const tenantSettings = await getTenantSettings(token, tenant);

        setTenantAllocationAlarmThresholdSetting(tenantSettings.allocation_alarm_threshold);
        setTenantAllocationAlarmMonthsSetting(tenantSettings.allocation_alarm_months);
        setTenantFinanceAlarmPercentageSetting(tenantSettings.finance_alarm_percentage);
        setTenantFinanceAlarmMonthsSetting(tenantSettings.finance_alarm_months);
    }

    async function fetchTenants(token) {
        const tenants = await getProfile(token);
        setTenants(tenants);
        const tenant = checkIfCookieLastTenantInCurrentTenants(cookies.last_tenant, tenants) ?? tenants.filter(ten => ten.id != '00000000-0000-0000-0000-000000000001')[0]?.id ?? '';
        setTenant(tenant);
        setRoles((await getFullRoles(token, tenant)));
        await fetchTenantSettings(token, tenant);
    }

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Login', 'Login page', true);
    }

    async function login() {
        const body = `grant_type=authorization_code&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&code=${code}&redirect_uri=${process.env.REACT_APP_COGNITO_LOGIN_URI}`
        setLoading(true);
        try {
            await axios.post(cognito_url + "/oauth2/token", body, config)
            .then(async result => {
                setRefreshToken(result.data.refresh_token)
                setAuthToken(result.data.access_token)
                await fetchTenants(result.data.access_token);
                await axios.get(cognito_url + "/oauth2/userInfo", {
                    headers: {
                        "Authorization": `Bearer ${result.data.access_token}`
                    }
                }).then(response => {
                    setCurrentUser(response.data.name);
                    setUserIdentifier(response.data.username);
                })
                
            });
            setIsLoggedIn(true);
            setLoading(false);
            navigate('/');
        } catch (e) {
            setIsLoggedIn(false);
            setLoading(false);
            navigate('/NotAuthenticated/500');
        }
    }

    React.useEffect(() => {
        if(code) {
            login();
        }
    }, []);


    return (
        <div className="d-flex justify-content-center flex-column align-items-center">
            <Helmet />
            <SquareLoader size={150} color="#0d94fc" loading={loading} aria-label='Logging in' />
            <h2>Logging in...</h2>
        </div>
    );
})

export default Login;
