import { Button, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../UtilFunctions";

const UserTableCompact = ({ users, tenant }) => {
  const navigate = useNavigate();

  return (
    <Table striped bordered hover className="mt-2">
      <thead className="text-center">
        <tr>
          <th>Username</th>
          <th>Roles</th>
          <th>Edit Roles</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => (
          <tr
            onClick={() => navigate(`/admin-panel/users/${user.user}`)}
            style={{ cursor: "pointer" }}
          >
            <td>{user.user}</td>
            <td>
              {user.roles.toString()}
            </td>
            <td className="text-center">
              <Button
                onClick={e => {
                  e.stopPropagation();
                  navigate(
                    `/admin-panel/tenants/${tenant}/users/${user.user}/roles`
                  );
                }}
              >
                Edit
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default UserTableCompact;
