import { useState } from "react";
import { Form, Alert, Breadcrumb, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { addTenant } from "../../../ApiConnection";
import { useAuthStore } from "../../../stores/AuthStore";
import { getHelmet } from "../../../UtilFunctions";

const CreateTenant = () => {
  const { token } = useAuthStore();
  const [name, setName] = useState('');
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const result = await addTenant(token, name);
      navigate(`/admin-panel/tenants/${result.id}`);
    } catch (e) {
      setError(e.response.data);
    }
  };

  const Helmet = () => {
    return getHelmet('Cloudway Accounts - Tenant Creation', 'Creation of tenant', true);
  }

  return (
    <div>
      <Helmet />
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item
          linkAs={Link}
          linkProps={{ to: "/admin-panel/tenants" }}
        >
          Tenants
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Create Tenant</Breadcrumb.Item>
      </Breadcrumb>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control onChange={(e) => setName(e.target.value)} />
        </Form.Group>
        {error && (
          <Alert className="mb-3" variant="danger">
            {error}
          </Alert>
        )}
        <Button variant="dark" type="submit">
          Add tenant
        </Button>
      </Form>
    </div>
  );
};

export default CreateTenant;
