import React, { useState } from "react";
import { observer } from "mobx-react";
import { useAuthStore } from "../stores/AuthStore";
import { Alert } from "react-bootstrap";
import { DateTime } from "luxon";

const ErrorField = () => {
    const { setShowAllocationAlarm, showAllocationAlarm, checkAllocationData, tenantAllocationAlarmThresholdSetting, setShowFinanceAlarm, showFinanceAlarm, checkFinanceData, tenantFinanceAlarmPercentageSetting } = useAuthStore();

    const checkError = () => {
        return showAllocationAlarm || showFinanceAlarm;
    }

    const setShow = (bool) => {
        setShowAllocationAlarm(bool)
        setShowFinanceAlarm(bool)
    }

    return (
        <div>
            {(checkError() &&
                <Alert className="mt-3" variant="danger" onClose={() => setShow(false)} dismissible>
                    {(showAllocationAlarm &&
                        <div>
                            <Alert.Heading>The allocation in some of the upcoming months is below the threshold: {tenantAllocationAlarmThresholdSetting}%</Alert.Heading>
                            <p>
                                Following months do not reach desired threshold: <br />
                                <ul>
                                    {
                                        checkAllocationData.map((value, index) => ({ [index + 1]: value })).filter((valueObject, index) => !valueObject[index + 1]).map(valueObject => (
                                            <>
                                                <li>{DateTime.now().plus({ month: Object.keys(valueObject)[0] }).monthLong}</li>

                                            </>
                                        ))
                                    }
                                </ul>
                            </p>
                        </div>
                    )}
                    {(showFinanceAlarm &&
                        <div>
                            <Alert.Heading>The cost/income in some of the upcoming months is below the required percentage: {tenantFinanceAlarmPercentageSetting}%</Alert.Heading>
                            <p>
                                Following months do not reach desired percentage: <br />
                                <ul>
                                    {
                                        checkFinanceData.map((value, index) => ({ [index + 1]: value })).filter((valueObject, index) => !valueObject[index + 1]).map(valueObject => (
                                            <>
                                                <li>{DateTime.now().plus({ month: Object.keys(valueObject)[0] }).monthLong}</li>

                                            </>
                                        ))
                                    }
                                </ul>
                            </p>
                        </div>
                    )}
                </Alert>
            )}
        </div>
    );
}

export default observer(ErrorField);
