import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../UtilFunctions";

const ProjectTable = ({projects}) => {

    const navigate = useNavigate();
    const routeChange = (id) => {
        const path = `/projects/${id}`;
        navigate(path);
    }

    return (
        <Table striped bordered hover className='mt-2'>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Customer</th>
                    <th>Start date</th>
                    <th>End date</th>
                    <th>Internal responsible</th>
                    <th>Customer responsible</th>
                </tr>
            </thead>
            <tbody>
                {projects.map(arrayData => {
                    return (
                        <tr onClick={() => routeChange(arrayData.id)} style={{cursor: 'pointer'}}>
                            <td>{arrayData.name}</td>
                            <td>{arrayData.customer_name}</td>
                            <td>{formatDate(arrayData.start_date)}</td>
                            <td>{formatDate(arrayData.end_date)}</td>
                            <td>{arrayData.internal_responsible.name} {arrayData.internal_responsible.lastname}</td>
                            <td>{arrayData.customer_responsible.name} {arrayData.customer_responsible.lastname}</td>
                        </tr>
                    )
                }
                )}
            </tbody>
        </Table>
    );
}

export default ProjectTable;