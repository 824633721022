import { useParams, Link, useNavigate } from 'react-router-dom'
import { Breadcrumb, Col, Row, Form, Alert, Button } from 'react-bootstrap';
import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { updateEmployee, getEmployeeDetail } from '../../ApiConnection'
import { formatdateformated, formatDate, getHelmet } from '../../UtilFunctions';
import { useAuthStore } from '../../stores/AuthStore';

const EditEmployee = () => {
    const { token, tenant } = useAuthStore();
    const { id } = useParams();
    const [employee, setEmployee] = React.useState("");
    const [name, setName] = React.useState("");
    const [lastname, setLastname] = React.useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [active, setActive] = useState(true);
    const [error, setError] = React.useState();
    

    const navigate = useNavigate();
    const routeChange = (id) => {
        const path = `/employees/${id}`;
        navigate(path);
    }

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Employee Edit', 'Make edits to employee', true);
    }

    React.useEffect(() => {
        async function getData() {
            const employee = await getEmployeeDetail(token, tenant, id);
            setName(employee.name);
            setLastname(employee.lastname);
            setStartDate(new Date(employee.start_date));
            setEndDate(employee.end_date ? new Date(employee.end_date) : null)
            setActive(employee.active === 1 ? true : false)
            setEmployee(employee);
        }
        getData()
    }, []);

    const handleSubmit = async event => {
        event.preventDefault();

        try {
            await updateEmployee(token, tenant, id, name, lastname, startDate, endDate, active)
            routeChange(id);
        } catch (error) {
            setError(error.response.data)
        }
    }

    return (
        <div>
            <Helmet />
            <Breadcrumb className="mt-3">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/employees" }} >Employees</Breadcrumb.Item>
                <Breadcrumb.Item active>Edit Employee</Breadcrumb.Item>
            </Breadcrumb>
            <h5>Edit Employee</h5>
            {(employee &&
                <form onSubmit={handleSubmit} >
                    <Form.Group className="mb-3">
                        <Form.Label>First name <span className='text-danger'>*</span></Form.Label>
                        <Form.Control type='text' onChange={e => setName(e.target.value.trim() === '' ? e.target.value.trim() : e.target.value.trimStart())} value={name} required />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Last name <span className='text-danger'>*</span></Form.Label>
                        <Form.Control type='text' onChange={e => setLastname(e.target.value.trim() === '' ? e.target.value.trim() : e.target.value.trimStart())} value={lastname} required />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Start date <span className='text-danger'>*</span></Form.Label>
                        <Form.Control onChange={e => setStartDate(new Date(e.target.value))} type="date" value={DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd')} required />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>End date</Form.Label>
                        <div className='d-flex align-items-center'>
                            <Form.Control className='w-75' onChange={e => setEndDate(new Date(e.target.value))} type="date" value={DateTime.fromJSDate(endDate).toFormat('yyyy-MM-dd')} />
                            <a className='link-primary ms-3' role='button' onClick={(() => setEndDate(null))}>reset</a>
                        </div>
                    </Form.Group>
                    {error &&
                        <Alert className={`mb-3 ${error ? 'd-none': ''}`} variant="danger">
                            {error}
                        </Alert>
                    }
                    <Button variant="dark" type="submit">Edit employee</Button>
                </form>
            )}
        </div>
    );
}

export default EditEmployee;
