import React from "react";
import { observer } from "mobx-react";
import { Percent } from "react-bootstrap-icons";
import { useAuthStore } from "../stores/AuthStore";
import { checkAllocation } from "../ApiConnection";

const AllocationAlarm = () => {
    const { setShowAllocationAlarm, showAllocationAlarm, setCheckAllocationData, setMonthsBelowAllocationThreshold, monthsBelowAllocationThreshold, tenant, isLoggedIn, token, tenantAllocationAlarmThresholdSetting, tenantAllocationAlarmMonthsSetting } = useAuthStore();

    React.useEffect( () => {
        async function fetchCheckAllocation() {
            try {
                const result = await checkAllocation(token, tenant, tenantAllocationAlarmThresholdSetting, tenantAllocationAlarmMonthsSetting);
                setMonthsBelowAllocationThreshold(result.reduce((acc, cur) => acc + !cur, 0));
                setCheckAllocationData(result);
            } catch (e) {
                setMonthsBelowAllocationThreshold(0);
                setCheckAllocationData({});
            }
        }
        if (isLoggedIn) { fetchCheckAllocation(); }
    }, [isLoggedIn, tenantAllocationAlarmThresholdSetting, tenantAllocationAlarmMonthsSetting]);


    const changeShow = () => {
        setShowAllocationAlarm(!showAllocationAlarm);
    }

    return (
        <div style={{ cursor: "pointer" }}>
            {(monthsBelowAllocationThreshold > 0 &&
                <span className="position-relative me-3" onClick={() => changeShow()}><Percent className="fs-5" color="red" /> <span className=" position-absolute top-0 start-100 translate-middle badge bg-danger rounded-circle badge-font-size">{monthsBelowAllocationThreshold}</span></span>
            )}
        </div>
    );
}

export default observer(AllocationAlarm);
