import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../UtilFunctions";
import SentimentComponent from './SentimentComponent';

const TouchPointTable = ({ touchPoints }) => {

    const navigate = useNavigate();
    const routeChange = (id) => {
        const path = `/touchpoints/${id}`;
        navigate(path);
    }

    if (touchPoints) {
        return (
            <Table striped bordered hover className='mt-2'>
                <thead>
                    <tr>
                        <th>Project</th>
                        <th>Sentiment</th>
                        <th>Date</th>
                        <th>Comment</th>
                        <th>Intern contact</th>
                        <th>Contact</th>
                    </tr>
                </thead>
                <tbody>
                    {touchPoints.map(arrayData => {
                        return (
                            <tr onClick={() => routeChange(arrayData.id)} style={{ cursor: 'pointer' }}>
                                <td>{arrayData.project}</td>
                                <td className="text-center"><SentimentComponent sentiment={arrayData.sentiment}></SentimentComponent></td>
                                <td>{formatDate(arrayData.date)}</td>
                                <td>{arrayData.comment}</td>
                                <td>{arrayData.intern_contact.name} {arrayData.intern_contact.lastname}</td>
                                <td>{arrayData.contact.name} {arrayData.contact.lastname}</td>
                            </tr>
                        )
                    }
                    )}
                </tbody>
            </Table>
        );
    } else {
        <p className="mt-2">No Touchpoints found</p>
    }
}

export default TouchPointTable;