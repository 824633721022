import React, { useState } from "react";
import { useAuthStore } from "../stores/AuthStore";
import { Dropdown, Navbar } from "react-bootstrap";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

const LoginWidget = () => {
    const { currentUser, isSuperUser, roles, tenant } = useAuthStore();

    const navigate = useNavigate();

    const logout = () => window.location.replace(`${process.env.REACT_APP_COGNITO_URL}/logout?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&logout_uri=${process.env.REACT_APP_COGNITO_LOGOUT_URI}`);

    return (
        <div>
            {currentUser
                ? <Dropdown>
                    <Dropdown.Toggle variant="dark" id="dropdown-basic">
                        {currentUser}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => { logout() }}>
                            Logout
                        </Dropdown.Item>
                        {isSuperUser &&
                            <Dropdown.Item onClick={() => navigate('/admin-panel/tenants')}>
                                Admin
                            </Dropdown.Item>
                        }
                        {roles.includes('Administrator') &&
                            <Dropdown.Item onClick={() => navigate(`/manage/${tenant}`)}>
                                Manage
                            </Dropdown.Item>
                        }
                        <Dropdown.Item onClick={() => navigate('/settings')}>
                            Settings
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                : <Navbar.Text className="ms-auto">
                    <a href={`${process.env.REACT_APP_COGNITO_URL}/login?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&response_type=code&scope=email+openid+phone+profile&redirect_uri=${process.env.REACT_APP_COGNITO_LOGIN_URI}`}>Login</a>
                </Navbar.Text>
            }
        </div>
    );
}

export default observer(LoginWidget);
