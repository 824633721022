import {useParams, Link, useNavigate} from 'react-router-dom'
import {Breadcrumb, Col, Row, Form, Alert, Button} from 'react-bootstrap';
import React, {useState} from 'react';
import {DateTime} from 'luxon';
import {updateWorkPeriod, getWorkPeriodDetail} from '../../ApiConnection'
import {getHelmet} from '../../UtilFunctions';
import {useAuthStore} from '../../stores/AuthStore';

const EditWorkPeriod = () => {
    const {token, tenant} = useAuthStore();
    const {id, workperiodid} = useParams();
    const [workPeriod, setWorkPeriod] = useState("");
    const [active, setActive] = useState();
    const [dateFrom, setDateFrom] = useState(new Date());
    const [dateTo, setDateTo] = useState(new Date());
    const [workPeriodType, setworkPeriodType] = useState('');
    const [weeklyAllocationPercentage, setweeklyAllocationPercentage] = useState();
    const [mondayAllocationPercentage, setMondayAllocationPercentage] = useState();
    const [tuesdayAllocationPercentage, setTuesdayAllocationPercentage] = useState();
    const [wednesdayAllocationPercentage, setWednesdayAllocationPercentage] = useState();
    const [thursdayAllocationPercentage, setThursdayAllocationPercentage] = useState();
    const [fridayAllocationPercentage, setFridayAllocationPercentage] = useState();
    const [detailedAllocationPercentage, setDetailedAllocationPercentage] = useState();
    const [error, setError] = useState();

    const navigate = useNavigate();
    const routeChange = () => {
        const path = `/assignments/${id}/workperiod/${workperiodid}`;
        navigate(path);
    }

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Work Period Edit', 'Make edits on work period', true);
    }

    React.useEffect(() => {
        async function getData() {
            const workPeriod = await getWorkPeriodDetail(token, tenant, workperiodid);
            setworkPeriodType(workPeriod.work_period_type);
            setDateFrom(new Date(workPeriod.date_from));
            setDateTo(new Date(workPeriod.date_to));
            setweeklyAllocationPercentage(workPeriod.weekly_allocation_percentage * 100);
            setMondayAllocationPercentage(workPeriod.monday_allocation_percentage * 100);
            setTuesdayAllocationPercentage(workPeriod.tuesday_allocation_percentage * 100);
            setWednesdayAllocationPercentage(workPeriod.wednesday_allocation_percentage * 100);
            setThursdayAllocationPercentage(workPeriod.thursday_allocation_percentage * 100);
            setFridayAllocationPercentage(workPeriod.friday_allocation_percentage * 100);
            setDetailedAllocationPercentage(workPeriod.detailed_allocation_percentage * 100);
            setActive(workPeriod.active === 1 ? "true" : "false")
            setWorkPeriod(workPeriod);
        }

        getData()
    }, []);

    const handleSubmit = async event => {
        event.preventDefault();

        try {
            await updateWorkPeriod(token, tenant, workperiodid, id, dateFrom, dateTo, workPeriodType, weeklyAllocationPercentage,
                mondayAllocationPercentage, tuesdayAllocationPercentage, wednesdayAllocationPercentage, thursdayAllocationPercentage,
                fridayAllocationPercentage, detailedAllocationPercentage, active === "true" ? true : false)
            routeChange();
        } catch (error) {
            setError(error.response.data)
        }
    }

    return (
        <div>
            <Helmet/>
            <Breadcrumb className="mt-3">
                <Breadcrumb.Item linkAs={Link} linkProps={{to: "/"}}>Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{to: "/assignments"}}>Assignments</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{to: `/assignments/${id}`}}>Employee Assignment
                    Details</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{to: `/assignments/${id}/workperiod/${workperiodid}`}}>Work
                    Period Details</Breadcrumb.Item>
                <Breadcrumb.Item active>Edit Work Period</Breadcrumb.Item>
            </Breadcrumb>
            <h5>Edit Work Period</h5>
            {(workPeriod &&
                <form onSubmit={handleSubmit}>
                    <Form.Group className='mb-3'>
                        <Form.Label>Date from</Form.Label>
                        <Form.Control onChange={e => setDateFrom(new Date(e.target.value))} type="date"
                                      value={DateTime.fromJSDate(dateFrom).toFormat('yyyy-MM-dd')} required/>
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label>Date to</Form.Label>
                        <Form.Control onChange={e => setDateTo(new Date(e.target.value))} type="date"
                                      value={DateTime.fromJSDate(dateTo).toFormat('yyyy-MM-dd')} required/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Active</Form.Label>
                        <Form.Control onChange={e => setActive(e.target.value)} value={active}/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Work Period Type</Form.Label>
                        <Form.Check
                            label={<div><p className="d-inline">Weekly</p></div>}
                            name="workPeriodType"
                            type='radio'
                            checked={workPeriodType === "WEEKLY"}
                            onChange={e => setworkPeriodType("WEEKLY")}
                            id={`inline-radio-1`}
                            required
                        />
                        <Form.Check
                            label={<div><p className="d-inline">Daily</p></div>}
                            name="workPeriodType"
                            type='radio'
                            checked={workPeriodType === "DAILY"}
                            onChange={e => setworkPeriodType("DAILY")}
                            id={`inline-radio-2`}
                            required
                        />
                        <Form.Check
                            name="workPeriodType"
                            label={<div><p className="d-inline">Detailed</p></div>}
                            type={'radio'}
                            checked={workPeriodType === "DETAILED"}
                            onChange={e => setworkPeriodType("DETAILED")}
                            id={`inline-radio-3`}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" as={Row}>
                        <Form.Label>Allocation percentages</Form.Label>
                        <Col xs="2">
                            <Form.Label>Weekly</Form.Label>
                        </Col>
                        <Col xs="1">
                            <Form.Control onChange={e => setweeklyAllocationPercentage(e.target.value)}
                                          value={weeklyAllocationPercentage}/>
                        </Col>
                        <Col xs="9">
                            <Form.Range
                                value={weeklyAllocationPercentage}
                                onChange={e => setweeklyAllocationPercentage(e.target.value)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group className="mb-3" as={Row}>
                        <Col xs="2">
                            <Form.Label>Monday</Form.Label>
                        </Col>
                        <Col xs="1">
                            <Form.Control onChange={e => setMondayAllocationPercentage(e.target.value)}
                                          value={mondayAllocationPercentage}/>
                        </Col>
                        <Col xs="9">
                            <Form.Range
                                value={mondayAllocationPercentage}
                                onChange={e => setMondayAllocationPercentage(e.target.value)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group className="mb-3" as={Row}>
                        <Col xs="2">
                            <Form.Label>Tuesday</Form.Label>
                        </Col>
                        <Col xs="1">
                            <Form.Control onChange={e => setTuesdayAllocationPercentage(e.target.value)}
                                          value={tuesdayAllocationPercentage}/>
                        </Col>
                        <Col xs="9">
                            <Form.Range
                                value={tuesdayAllocationPercentage}
                                onChange={e => setTuesdayAllocationPercentage(e.target.value)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group className="mb-3" as={Row}>
                        <Col xs="2">
                            <Form.Label>Wednesday</Form.Label>
                        </Col>
                        <Col xs="1">
                            <Form.Control onChange={e => setWednesdayAllocationPercentage(e.target.value)}
                                          value={wednesdayAllocationPercentage}/>
                        </Col>
                        <Col xs="9">
                            <Form.Range
                                value={wednesdayAllocationPercentage}
                                onChange={e => setWednesdayAllocationPercentage(e.target.value)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group className="mb-3" as={Row}>
                        <Col xs="2">
                            <Form.Label>Thursday</Form.Label>
                        </Col>
                        <Col xs="1">
                            <Form.Control onChange={e => setThursdayAllocationPercentage(e.target.value)}
                                          value={thursdayAllocationPercentage}/>
                        </Col>
                        <Col xs="9">
                            <Form.Range
                                value={thursdayAllocationPercentage}
                                onChange={e => setThursdayAllocationPercentage(e.target.value)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group className="mb-3" as={Row}>
                        <Col xs="2">
                            <Form.Label>Friday</Form.Label>
                        </Col>
                        <Col xs="1">
                            <Form.Control onChange={e => setFridayAllocationPercentage(e.target.value)}
                                          value={fridayAllocationPercentage}/>
                        </Col>
                        <Col xs="9">
                            <Form.Range
                                value={fridayAllocationPercentage}
                                onChange={e => setFridayAllocationPercentage(e.target.value)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group className="mb-3" as={Row}>
                        <Col xs="2">
                            <Form.Label>Detailed</Form.Label>
                        </Col>
                        <Col xs="1">
                            <Form.Control onChange={e => setDetailedAllocationPercentage(e.target.value)}
                                          value={detailedAllocationPercentage}/>
                        </Col>
                        <Col xs="9">
                            <Form.Range
                                value={detailedAllocationPercentage}
                                onChange={e => setDetailedAllocationPercentage(e.target.value)}
                            />
                        </Col>
                    </Form.Group>
                    {error &&
                        <Alert className={`mb-3 ${error ? 'd-none' : ''}`} variant="danger">
                            {error}
                        </Alert>
                    }
                    <Button variant="dark" type="submit">Edit work period</Button>
                </form>
            )}
        </div>
    );
}

export default EditWorkPeriod;