import {Table} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {formatDate} from "../../UtilFunctions";
import React from "react";

const WorkPeriodTable = ({workPeriods}) => {

    const navigate = useNavigate();
    const routeChange = (id, employeeAssignmentId) => {
        const path = `/assignments/${employeeAssignmentId}/workperiod/${id}`;
        navigate(path);
    }

    return (
        <Table striped bordered hover className='mt-2'>
            <thead>
            <tr>
                <th>Work period type</th>
                <th>Date from</th>
                <th>Date to</th>
                <th>Weekly allocation percentage</th>
                <th>Monday allocation percentage</th>
                <th>Tuesday allocation percentage</th>
                <th>Wednesday allocation percentage</th>
                <th>Thursday allocation percentage</th>
                <th>Friday allocation percentage</th>
                <th>Detailed allocation percentage</th>
                <th>Active</th>
            </tr>
            </thead>
            <tbody>
            {workPeriods?.map(arrayData => {
                    return (
                        <tr onClick={() => routeChange(arrayData.id, arrayData.employee_assignment_id)}
                            style={{cursor: 'pointer'}}>
                            <td>{arrayData.work_period_type[0] + arrayData.work_period_type.toLowerCase().slice(1)}</td>
                            <td>{formatDate(arrayData.date_from)}</td>
                            <td>{formatDate(arrayData.date_to)}</td>
                            <td>{Math.round(arrayData.weekly_allocation_percentage * 100)}%</td>
                            <td>{Math.round(arrayData.monday_allocation_percentage * 100)}%</td>
                            <td>{Math.round(arrayData.tuesday_allocation_percentage * 100)}%</td>
                            <td>{Math.round(arrayData.wednesday_allocation_percentage * 100)}%</td>
                            <td>{Math.round(arrayData.thursday_allocation_percentage * 100)}%</td>
                            <td>{Math.round(arrayData.friday_allocation_percentage * 100)}%</td>
                            <td>{Math.round(arrayData.detailed_allocation_percentage * 100)}%</td>
                            <td>{arrayData.active ? 'Yes' : 'No'}</td>
                        </tr>
                    )
                }
            )}
            </tbody>
        </Table>
    );
}

export default WorkPeriodTable;