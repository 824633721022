import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../UtilFunctions";

const ContactTable = ({contacts}) => {
    const navigate = useNavigate();

    return (
        <div className='w-100'>
            <Table striped bordered hover className='mt-2'>
                <thead>
                    <tr>
                        <th>First name</th>
                        <th>Last name</th>
                        <th>email</th>
                        <th>created</th>
                    </tr>
                </thead>
                <tbody>
                    {contacts.map(contact => {
                        return (
                            <tr onClick={() => navigate(`/contacts/${contact.id}`)} style={{cursor: 'pointer'}}>
                                <td>{contact.name}</td>
                                <td>{contact.lastname}</td>
                                <td>{contact.email}</td>
                                <td>{formatDate(contact.created_at)}</td>
                            </tr>
                        )
                    }
                    )}
                </tbody>
            </Table>
        </div>
    );
}

export default ContactTable;