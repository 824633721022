import { Breadcrumb, Button, Modal, Col, Row, Tooltip, OverlayTrigger, Tab, Tabs } from 'react-bootstrap'
import { useNavigate, Link, useParams } from 'react-router-dom'
import React from "react";
import { getCustomerTouchPoint, deleteCustomerTouchPoint } from '../../ApiConnection'
import { calculateTimeBetween, formatDate, getHelmet } from '../../UtilFunctions';
import { useAuthStore } from '../../stores/AuthStore';
import TouchPointAssignmentTable from "./TouchPointAssignmentTable";
import SentimentComponent from './SentimentComponent'
import CommentSection from '../comment/CommentSection';


const TouchPointDetail = () => {
    const { token, tenant } = useAuthStore();
    const { id } = useParams();
    const [touchPoint, setTouchPoint] = React.useState(null);
    const [show, setShow] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleDelete = async () => {
        await deleteCustomerTouchPoint(token, id);
        const path = `/touchpoints`;
        navigate(path);
    }

    const navigate = useNavigate();
    const routeChange = (uuid) => {
        const path = `/touchpoints/edit/${uuid}`;
        navigate(path);
    }

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Customer Touch Point Details', 'Details of a customer touch point', true);
    }

    React.useEffect(() => {
        async function getData() {
            try {
                setTouchPoint(await getCustomerTouchPoint(token, tenant, id));
            } catch (e) {
                navigate(`/NotAuthenticated/${e.response.status}`);
            }
        }
        getData()
    }, []);

    if (!touchPoint) return null;

    return (
        <div>
            <Helmet />
            <Breadcrumb className="mt-3">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/touchpoints" }} >Touchpoints</Breadcrumb.Item>
                <Breadcrumb.Item active>Touchpoint Details</Breadcrumb.Item>
            </Breadcrumb>
            <Row>
                <Col>
                    <h5>Touchpoint details</h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>Customer name</small>
                    <p><Link to={`/customers/${touchPoint.customer_id}`}>{touchPoint.customer_name}</Link></p>
                </Col>
                <Col>
                    <small className='text-muted'>Project name</small>
                    <p><Link to={`/projects/${touchPoint.project}`}>{touchPoint.project_name}</Link></p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>Comment</small>
                    <p>{touchPoint.comment}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>Sentiment</small>
                    <p><SentimentComponent sentiment={touchPoint.sentiment}></SentimentComponent></p></Col>
                <Col>
                    <small className='text-muted'>Date</small>
                    <p>{formatDate(touchPoint.date)}</p>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col>
                    <small className='text-muted'>Intern contact</small>
                    <br />
                    <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        overlay={(props) => (
                            <Tooltip {...props}>
                                {touchPoint.intern_contact.email}
                            </Tooltip>
                        )} >
                        <p className='d-inline'><Link to={`/contacts/${touchPoint.intern_contact.id}`}>{touchPoint.intern_contact.name} {touchPoint.intern_contact.lastname}</Link></p>
                    </OverlayTrigger>
                </Col>
                <Col>
                    <small className='text-muted'>Contact</small>
                    <br />
                    <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        overlay={(props) => (
                            <Tooltip {...props}>
                                {touchPoint.contact.email}
                            </Tooltip>
                        )} >
                        <p className='d-inline'><Link to={`/contacts/${touchPoint.contact.id}`}>{touchPoint.contact.name} {touchPoint.contact.lastname}</Link></p>
                    </OverlayTrigger>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>Created</small>
                    <p>{calculateTimeBetween(touchPoint.created_at)}</p>
                </Col>
                <Col>
                    <small className='text-muted'>Created by</small>
                    <p>{touchPoint.created_by}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>Last modified</small>
                    <p>{calculateTimeBetween(touchPoint.modified_at)}</p>
                </Col>
                <Col>
                    <small className='text-muted'>Last modified by</small>
                    <p>{touchPoint.last_modified_by}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button onClick={() => routeChange(touchPoint.id)} variant="dark">Edit</Button>{' '}
                    <Button onClick={handleShow} variant="dark">Remove</Button>{' '}
                </Col>
            </Row>
            <Tabs defaultActiveKey="assignments" className="mb-3 mt-2" id="uncontrolled-tab-example">
                <Tab eventKey="assignments" title="Assignments">
                    <h5>Assignments</h5>
                    <TouchPointAssignmentTable assignments={touchPoint.assignments.map(emp => ({ ...emp, project_name: touchPoint.project_name }))} />
                </Tab>
                <Tab eventKey="comments" title="Comments">
                    <CommentSection resource={id} />
                </Tab>
            </Tabs>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Touchpoint</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure, you want to delete this touchpoint!</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="dark" onClick={() => { handleDelete() }}>
                        Delete Touchpoint
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default TouchPointDetail;
