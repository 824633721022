import { Breadcrumb, Button, Modal, Row, Col, Tab, Tabs } from 'react-bootstrap'
import { useNavigate, Link, useParams, useLocation } from 'react-router-dom'
import React, { useState } from "react";
import { getCustomerDetail, deleteCustomer, getCustomerTouchPointsByCustomer } from '../../ApiConnection'
import { calculateTimeBetween, getHelmet } from '../../UtilFunctions';
import EmployeeAssignmentTableCompact from '../assignment/EmployeeAssignmentTableCompact';
import { useAuthStore } from '../../stores/AuthStore';
import TouchPointTable from '../touchpoint/TouchPointTable'
import ProjectTableCompact from '../project/ProjectTableCompact';
import { DateTime } from 'luxon';
import Select from 'react-select'
import { CostIncomeBarChart, IncomeBarChart } from '../charts';
import { observer } from 'mobx-react';

const CustomerDetail = () => {
    const { token, tenant, roles } = useAuthStore();
    const { id } = useParams();
    const [customer, setCustomer] = React.useState(null);
    const [touchPoints, setTouchPoints] = React.useState(null);
    const [projects, setProjects] = React.useState([]);
    const [selectedProjects, setSelectedProjects] = React.useState([]);

    const [show, setShow] = React.useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleDelete = async (uuid) => {
        await deleteCustomer(token, tenant, uuid);
        const path = `/customers`;
        navigate(path);
    }

    function handleSelect(data) {
        setSelectedProjects(data);
    }

    const routeChange = (uuid) => {
        const path = `/customers/edit/${uuid}`;
        navigate(path);
    }

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Customer Details', 'Details of a customer', true);
    }

    React.useEffect(() => {
        async function getData() {
            try {
                setCustomer(await getCustomerDetail(token, tenant, id));
                setTouchPoints(await getCustomerTouchPointsByCustomer(token, tenant, id));
            } catch (e) {
                navigate(`/NotAuthenticated/${e.response.status}`, {
                    state: { prevLocation: location.pathname }
                });
            }
        }
        getData()
    }, []);

    React.useEffect(() => {
        const projectArray = [];
        customer?.projects.forEach(element => {
            projectArray.push({ value: element.project_id, label: `${element.project_name}` })
        });
        setProjects(projectArray);
    }, [customer])

    if (!customer) return null;

    return (
        <div>
            <Helmet />
            <Breadcrumb className="mt-3">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/customers" }} >Customers</Breadcrumb.Item>
                <Breadcrumb.Item active>Customer Details</Breadcrumb.Item>
            </Breadcrumb>
            <Row>
                <Col>
                    <h5>Customer details</h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>Name</small>
                    <p>{customer.name}</p>
                </Col>
                <Col>
                    <small className='text-muted'>Track contact activity</small>
                    <p>{customer.track_contact_activity ? 'Yes' : 'No'}</p>
                </Col>
                <Col>
                    <small className='text-muted'>Active</small>
                    <p>{customer.active ? 'Yes' : 'No'}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>Created</small>
                    <p>{calculateTimeBetween(customer.created_at)}</p>
                </Col>
                <Col>
                    <small className='text-muted'>Created by</small>
                    <p>{customer.created_by}</p>
                </Col>
                <Col></Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>Last modified</small>
                    <p>{calculateTimeBetween(customer.modified_at)}</p>
                </Col>
                <Col>
                    <small className='text-muted'>Last modified by</small>
                    <p>{customer.last_modified_by}</p>
                </Col>
                <Col></Col>
            </Row>
            <Row>
                <Col>
                    <Button onClick={() => routeChange(customer.id)} variant="dark">Edit</Button>{' '}
                    <Button onClick={handleShow} variant="dark">Remove</Button>{' '}
                </Col>
            </Row>
            <Tabs defaultActiveKey={roles.includes('Sales') ? "costIncomeChart" : 'projects'} className="mb-3 mt-2" id="uncontrolled-tab-example">
                {roles.includes('Financial') ?
                    <Tab eventKey="costIncomeChart" title="Cost/Income visualization">
                        <h5>Cost-Income</h5>
                        <CostIncomeBarChart on='customer' refresh />
                    </Tab>
                : roles.includes('Sales') ?
                    <Tab eventKey="costIncomeChart" title="Income visualization">
                        <h5>Income</h5>
                        <IncomeBarChart on='customer' refresh />
                    </Tab>
                : null
                }
                <Tab eventKey="projectsHistory" title="Project history">
                    <h5>Project history</h5>
                    <ProjectTableCompact projects={customer.projects.filter(project => DateTime.now() > DateTime.fromISO(project.project_end_date)).map(project => ({ customer_name: customer.name, ...project }))}></ProjectTableCompact>
                </Tab>
                <Tab eventKey="projects" title="Current projects">
                    <div className='d-flex'>
                         <h5>Current projects</h5>
                         <Button className="w-25 ms-auto" onClick={() => navigate(`/projects/create`, { state: { customer: id } })} variant="dark">Add Project</Button>
                     </div>
                    <ProjectTableCompact projects={customer.projects.filter(project => DateTime.now() > DateTime.fromISO(project.project_start_date) && DateTime.now() < DateTime.fromISO(project.project_end_date)).map(project => ({ customer_name: customer.name, ...project }))}></ProjectTableCompact>
                </Tab>
                <Tab eventKey="futureprojects" title="Future projects">
                    <h5>Future projects</h5>
                    <ProjectTableCompact projects={customer.projects.filter(project => DateTime.now() < DateTime.fromISO(project.project_start_date)).map(project => ({ customer_name: customer.name, ...project }))}></ProjectTableCompact>
                </Tab>
                <Tab eventKey="assignmentsHistory" title="Assignment history">
                    <h5>Assignment History</h5>
                    <Select
                        isMulti
                        value={selectedProjects}
                        onChange={handleSelect}
                        options={projects}
                    />
                    <EmployeeAssignmentTableCompact assignments={customer.projects.filter(project => (selectedProjects.some(selectedProject => selectedProject.value === project.project_id) || selectedProjects.length === 0)).reduce((acc, cur) => [...acc, ...cur.assignments?.map(assignment => ({ project_name: cur.project_name, ...assignment })) || []], []).filter(emp => DateTime.fromISO(emp.end_date) < DateTime.now())} companyView={true} companyName={customer.name} />
                </Tab>
                <Tab eventKey="activeAssignments" title="Current assignments">
                    <h5>Current assignments</h5>
                    <Select
                        isMulti
                        value={selectedProjects}
                        onChange={handleSelect}
                        options={projects}
                    />
                    <EmployeeAssignmentTableCompact assignments={customer.projects.filter(project => (selectedProjects.some(selectedProject => selectedProject.value === project.project_id) || selectedProjects.length === 0)).reduce((acc, cur) => [...acc, ...cur.assignments?.map(assignment => ({ project_name: cur.project_name, ...assignment })) || []], []).filter(emp => DateTime.fromISO(emp.start_date) <= DateTime.now() && DateTime.fromISO(emp.end_date) >= DateTime.now())} companyView={true} companyName={customer.name} />
                </Tab>
                <Tab eventKey="futureassignments" title="Future assignments">
                    <h5>Future assignments</h5>
                    <Select
                        isMulti
                        value={selectedProjects}
                        onChange={handleSelect}
                        options={projects}
                    />
                    <EmployeeAssignmentTableCompact assignments={customer.projects.filter(project => (selectedProjects.some(selectedProject => selectedProject.value === project.project_id) || selectedProjects.length === 0)).reduce((acc, cur) => [...acc, ...cur.assignments?.map(assignment => ({ project_name: cur.project_name, ...assignment })) || []], []).filter(emp => DateTime.fromISO(emp.start_date) > DateTime.now())} companyView={true} companyName={customer.name} />
                </Tab>
                <Tab eventKey="customerTouchPoints" title="Customer touchpoints">
                    <h5>Customer Touchpoints</h5>
                    <Select
                        isMulti
                        value={selectedProjects}
                        onChange={handleSelect}
                        options={projects}
                    />
                    <TouchPointTable touchPoints={touchPoints} />
                </Tab>
            </Tabs>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Customer</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure, you want to delete this Customer!</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="dark" onClick={() => { handleDelete(customer.id) }}>
                        Delete Customer
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default observer(CustomerDetail);
