import React, { useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom'
import { useAuthStore } from '../../stores/AuthStore';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { observer } from 'mobx-react';
import { checkJwtExpired } from '../../UtilFunctions';
import { getHelmet } from '../../UtilFunctions';

const config = { 
    headers: {
        "Content-Type": 'application/x-www-form-urlencoded',
    }
}

const NotAuthenticated = observer(() => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { token, setAuthToken, refreshToken, setIsLoggedIn } = useAuthStore();
    const { status } = useParams();
    const [isRefreshing, setRefreshing] = useState(false);

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Not Authenticated Page', 'Error page to be redirected to when you try to access resource(s) to which you do not have access', true);
    }

    React.useEffect(() => {
        async function refresh() {
            if (await checkJwtExpired(token)) {
                setRefreshing(true);
                const body  = `client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&grant_type=refresh_token&redirect_uri=${process.env.REACT_APP_COGNITO_LOGIN_URI}&refresh_token=${refreshToken}`;
                try {
                    const result = await axios.post(`${process.env.REACT_APP_COGNITO_URL}/oauth2/token`, body, config);
                    setAuthToken(result.data.access_token);
                    setRefreshing(false);
                    navigate(state?.prevLocation || '/');
                } catch (e) {
                    setIsLoggedIn(false);
                    setRefreshing(false);
                }
            }
        }
        if (status == 401) refresh();
    }, []);

    if(status == 401 && isRefreshing) {
        return (
            <div>
                <Helmet />
                Refreshing...
            </div>
        );
    } else if(status == 403) {
        return (
            <div>
                <Helmet />
                <h1>No Rights to access page. If this is a mistake, contact your administrator.</h1>
                <a href={`${process.env.REACT_APP_COGNITO_URL}/login?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&response_type=code&scope=email+openid+phone+profile&redirect_uri=${process.env.REACT_APP_COGNITO_LOGIN_URI}`}>Login</a>
            </div>
        );
    } else if(status == 401) {
        return (
            <div>
                <Helmet />
                <h1>Not Authenticated</h1>
                <a href={`${process.env.REACT_APP_COGNITO_URL}/login?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&response_type=code&scope=email+openid+phone+profile&redirect_uri=${process.env.REACT_APP_COGNITO_LOGIN_URI}`}>Login</a>
            </div>
        );
    } else {
        return (
            <div className='d-flex flex-column'>
                <Helmet />
                <h1>Something went wrong</h1>
                <Link to='/'>Go back to home page</Link>
                <a href={`${process.env.REACT_APP_COGNITO_URL}/login?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&response_type=code&scope=email+openid+phone+profile&redirect_uri=${process.env.REACT_APP_COGNITO_LOGIN_URI}`}>Login</a>
            </div>
        );
    }
});

export default NotAuthenticated;
