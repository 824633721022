import { getHelmet } from "../UtilFunctions";

const Home = () => {
  const Helmet = () => {
    return getHelmet('Cloudway Accounts - Home Page', 'Dashboard with multiple charts regarding allocation and customer touchpoints', true);
  }

  return (
    <div>
      <Helmet />
      <h1>Dashboard</h1>
    </div>
  );
}

export default Home;
