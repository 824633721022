import { BrowserRouter as Router, Outlet, Route, Routes } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { Components } from './components/index'
import CheckAuth from './components/authentication/CheckAuth';
import Navigationbar from './navigationbar/Navigationbar';
import { getHelmet } from './UtilFunctions';
import CheckAuthAdminPanel from './components/authentication/CheckAuthAdminPanel';

function App() {

  const RouteWithNavbar = () => (
    <>
      <Navigationbar />
      <Container className='py-5'>
        <CheckAuth path='/' allowedRoles={['ReadOnly']}>
          <Outlet />
        </CheckAuth>
      </Container>
    </>
  );

  const RouteWithoutNavbar = () => (
    <Container className='py-5'>
      <Outlet />
    </Container>
  );

  const AdminPanel = () => (
    <>
      <Components.NaigationBarAdmin />
      <Container className='py-5'>
        <CheckAuthAdminPanel path='/admin-panel/tenants' ><Outlet /></CheckAuthAdminPanel>
      </Container>
    </>
  );

  const ManagePanel = () => (
    <>
      <Components.NavigationBarManage />
      <Container className='py-5'>
        <CheckAuth path='/manage' allowedRoles={['Administrator']}>
          <Outlet />
        </CheckAuth>
      </Container>
    </>
  );
  
  const Helmet = () => {
    return getHelmet('Cloudway Accounts', 'Cloudway Accounts application', true);
  }

  return (
    <div className="App">
      <Helmet />
      <Router>
        <Routes>
          <Route path="/" element={<RouteWithNavbar />}>
            <Route index element={<Components.Dashboard />} />
            <Route path='dashboard' element={<Components.Dashboard />} />
            <Route path='/assignments/'>
              <Route index element={<Components.Assignments />} />
              <Route path=':id'>
                <Route index element={<Components.EmployeeAssignmentDetail />} />
                <Route path='workperiod/:workperiodid' element={<CheckAuth allowedRoles={['Administrator']}><Components.WorkPeriodDetail /></CheckAuth>} />
                <Route path='workperiod/edit/:workperiodid' element={<CheckAuth allowedRoles={['Administrator']}><Components.EditWorkPeriod /></CheckAuth>} />
                <Route path='workperiod/create' element={<CheckAuth allowedRoles={['Administrator']}><Components.CreateWorkPeriod /></CheckAuth>} />
                <Route path='income/create' element={<CheckAuth allowedRoles={['Sales']}><Components.CreateEmployeeAssignmentIncome /></CheckAuth>} />
                <Route path='income/edit/:incomeid' element={<CheckAuth allowedRoles={['Sales']}><Components.EditEmployeeAssignmentIncome /></CheckAuth>} />
              </Route>
              <Route path='create' element={<CheckAuth allowedRoles={['Administrator']}><Components.CreateEmployeeAssignment /></CheckAuth>} />
              <Route path='edit/:id' element={<CheckAuth allowedRoles={['Administrator']}><Components.EditEmployeeAssignment /></CheckAuth>} />
            </Route>
            <Route path='/employees/'>
              <Route index element={<Components.Employees />} />
              <Route path=':id'>
                <Route index element={<Components.EmployeeDetail />} />
                <Route path='cost/create' element={<CheckAuth allowedRoles={['Financial']}><Components.CreateEmployeeCost /></CheckAuth>} />
                <Route path='cost/edit/:costid' element={<CheckAuth allowedRoles={['Financial']}><Components.EditEmployeeCost /></CheckAuth>} />
              </Route>
              <Route path='create' element={<CheckAuth allowedRoles={['Administrator']}><Components.CreateEmployee /></CheckAuth>} />
              <Route path='edit/:id' element={<CheckAuth allowedRoles={['Administrator']}><Components.EditEmployee /></CheckAuth>} />
              <Route path='search' element={<Components.AvailableEmployees />} />
            </Route>
            <Route path='/customers/'>
              <Route index element={<Components.Customers />} />
              <Route path=':id' element={<Components.CustomerDetail />} />
              <Route path='create' element={<CheckAuth allowedRoles={['Administrator']}><Components.CreateCustomer /></CheckAuth>} />
              <Route path='edit/:id' element={<CheckAuth allowedRoles={['Administrator']}><Components.EditCustomer /></CheckAuth>} />
            </Route>
            <Route path='/projects/'>
              <Route index element={<Components.Projects />} />
              <Route path=':id' element={<Components.ProjectDetail />} />
              <Route path='create' element={<CheckAuth allowedRoles={['Administrator']}><Components.CreateProject /></CheckAuth>} />
              <Route path='edit/:id' element={<CheckAuth allowedRoles={['Administrator']}><Components.EditProject /></CheckAuth>} />
            </Route>
            <Route path='/touchpoints/'>
              <Route index element={<Components.CustomerTouchPoints />} />
              <Route path=':id' element={<Components.TouchPointDetail />} />
              <Route path='create/:id' element={<CheckAuth allowedRoles={['Administrator']}><Components.CreateTouchPoint /></CheckAuth>} />
              <Route path='edit/:id' element={<CheckAuth allowedRoles={['Administrator']}><Components.EditTouchPoint /></CheckAuth>} />
            </Route>
            <Route path='/contacts'>
              <Route index element={<Components.Contacts />} />
              <Route path=':id' element={<Components.ContactDetail />} />
              <Route path='create' element={<CheckAuth allowedRoles={['Administrator']}><Components.CreateContact /></CheckAuth>} />
              <Route path='edit/:id' element={<CheckAuth allowedRoles={['Administrator']}><Components.EditContact /></CheckAuth>} />
            </Route>
            <Route path='Contact' element={<CheckAuth path='/Contact' ><Components.Contact /></CheckAuth>} />
            <Route path='settings' element={<Components.Settings />} />
          </Route>

          <Route element={<AdminPanel />}>
            <Route path='/admin-panel/tenants'>
              <Route index element={<Components.Tenants />} />
              <Route path=':id' element={<Components.TenantDetail />} />
              <Route path='create' element={<Components.CreateTenant />} />
              <Route path=':tenant/users/:username/roles' element={<Components.Roles />} />
              <Route path=':tenant/users/:username/roles/create' element={<Components.CreateRoles />} />
            </Route>
            <Route path='/admin-panel/users'>
              <Route index element={<Components.Users />} />
              <Route path=':username' element={<Components.UserDetail />} />
              <Route path='create' element={<Components.CreateUser />} />
              <Route path=':username/add-roles' element={<Components.CreateRolesAnyTenant />} />
            </Route>
          </Route>

          <Route element={<ManagePanel />}>
            <Route path='/manage/:id'>
              <Route index element={<Components.TenantDetailManage />} />
              <Route path='create-roles' element={<Components.CreateRolesAnyUser />} />
              <Route path='users'>
                <Route index element={<Components.UsersManage />} />
                <Route path=':username/roles' element={<Components.RolesManage />} />
                <Route path=':username/roles/add-roles' element={<Components.CreateRolesManage />} />
                <Route path=':username' element={<Components.UserDetailManage />} />
                <Route path='create' element={<Components.CreateUserManage />} />
              </Route>
            </Route>
          </Route>

          <Route element={<RouteWithoutNavbar />}>
            <Route path='/login' element={<Components.Login />} />
            <Route path='/logout' element={<Components.Logout />} />
            <Route path='/NotAuthenticated/:status' element={<Components.NotAuthenticated />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;