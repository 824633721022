import React from "react";
import { Button, ToggleButton, Container, Spinner, Breadcrumb } from "react-bootstrap";
import ProjectTable from "./ProjectTable";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom"
import { getProjects } from "../../ApiConnection"
import { useAuthStore } from "../../stores/AuthStore";
import { dateSort, getHelmet } from "../../UtilFunctions";
import SearchBar from "../SearchBar";

const Projects = () => {
    const { token, tenant } = useAuthStore();
    const [projects, setprojects] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState('');
    const [error, setError] = React.useState(null);
    const [active, setActive ] = React.useState(true);
    const [loading, setLoading ] = React.useState(true);

    const location = useLocation();
    const navigate = useNavigate();
    const routeChange = () => {
        const path = `/projects/create`;
        navigate(path);
    }

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Project Table', 'Listing of all projects', true);
    }

    const filterProjects = (project) => {
        if(active) {
            return (project.name.toLowerCase().includes(searchValue) || project.customer_name.toLowerCase().includes(searchValue)) && project.active === +active;
        }
        return project.name.toLowerCase().includes(searchValue) || project.customer_name.toLowerCase().includes(searchValue);
    } 

    React.useEffect(() => {
        async function getData() {
            try {
                setprojects((await getProjects(token, tenant)).sort(dateSort));
                setLoading(false);
            } catch(e) {
                setError(e);
                setLoading(false);
            }
        }
        getData()
    }, []);

    React.useEffect(() => {
        if(error) {
            const status = error.response.status;
            navigate(`/NotAuthenticated/${status}`, {
                state: { prevLocation: location.pathname}
            });
        }
    }, [error]);

    function Table(){
        return(
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {loading ? <Spinner className='mt-2' animation="border" /> : <ProjectTable projects={projects.filter(filterProjects).sort(dateSort)} />}
            </div>
        )
    }

    return (
        <Container>
            <Helmet />
            <div className="d-flex flex-column">
                <Breadcrumb className="mt-2">
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Projects</Breadcrumb.Item>
                </Breadcrumb>
                <div className="d-flex justify-content-center align-items-center">
                    <SearchBar className="w-75" setSearchValue={setSearchValue} searchingOn={'name, customer'}></SearchBar>
                    <ToggleButton
                        className="w-10 ms-2"
                        id="toggle-check"
                        type="checkbox"
                        variant="outline-dark"
                        checked={active}
                        value="1"
                        onChange={(e) => setActive(e.currentTarget.checked)}>
                        Active
                    </ToggleButton>
                    <Button className="w-25 ms-2" onClick={routeChange} variant="dark">Add Project</Button>
                </div>
            </div>
            <Table></Table>
        </Container>
    );
}

export default Projects;
