import { useEffect, useState } from "react";
import { Breadcrumb, Col, Row, Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getTenant, getUsersFromTenant } from "../../../ApiConnection";
import { useAuthStore } from "../../../stores/AuthStore";
import { formatDate, getHelmet } from "../../../UtilFunctions";
import UserTableCompact from "../user/UserTableCompact";

const TenantDetail = () => {
  const { token } = useAuthStore();
  let { id } = useParams();
  const [tenant, setTenant] = useState(null);
  const [users, setusers] = useState([]);
  const [loading, setLoading] = useState(true);

  const Helmet = () => {
    return getHelmet('Cloudway Accounts - Tenant Details', 'Details of a tenant', true);
 }

  const location = useLocation();
  const navigate = useNavigate();

  const getData = async () => {
    try {
      setTenant(await getTenant(token, id));
      setusers(await getUsersFromTenant(token, id));
      setLoading(false);
    } catch (e) {
      navigate(`/NotAuthenticated/${e.response.status}`, {
        state: { prevLocation: location.pathname },
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (!loading)
    <div className="d-flex justify-content-center align-items-center">
      <Spinner className="mt-2" animation="border"></Spinner>
    </div>;

  if (!tenant) return null;

  return (
    <div>
      <Helmet />
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item
          linkAs={Link}
          linkProps={{ to: "/admin-panel/tenants" }}
        >
          Tenants
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Tenant Details</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col>
          <h5>Tenant details</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <small className="text-muted">Name</small>
          <p>{tenant.name}</p>
        </Col>
        <Col>
          <small className="text-muted">Created at</small>
          <p>{formatDate(tenant.created_at)}</p>
        </Col>
        <Col>
          <small className="text-muted">Created by</small>
          <p>{tenant.created_by}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <small className="text-muted">Modified at</small>
          <p>{formatDate(tenant.modified_at)}</p>
        </Col>
        <Col>
          <small className="text-muted">Last modified by</small>
          <p>{tenant.last_modified_by}</p>
        </Col>
        <Col></Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <h5>Users</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <UserTableCompact users={users.sort((a, b) => a - b)} tenant={id} />
        </Col>
      </Row>
    </div>
  );
};

export default TenantDetail;
