import { useEffect, useState } from "react";
import { DateTime } from "luxon"
import { Button, Container, Spinner, Form } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthStore } from "../../stores/AuthStore";
import { getHelmet } from "../../UtilFunctions";
import AvailableEmployeeTable from "./AvailableEmployeeTable";
import SortDropdown from "./dropdowns/SortDropDown";
import { getAvailableEmployees } from "../../ApiConnection";

const AvailableEmployees = () => {
    const { token, tenant } = useAuthStore();
    const [employees, setEmployees] = useState([]);
    const [startDate, setStartDate] = useState(DateTime.now().toJSDate());
    const [endDate, setEndDate] = useState(DateTime.now().plus({ week: 2 }).toJSDate());
    const [allocation, setAllocation] = useState(0);
    const [includeNonBillable, setIncludeNonBillable] = useState(false);
    const [sort, setSort] = useState('desc');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const location = useLocation();
    const navigate = useNavigate();

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Available Employee Search', 'Listing of all available employees within search criteria');
    }

    const sortMap = {
        'asc': (a, b) => a.allocation > b.allocation ? 1 : -1,
        'desc': (a, b) => a.allocation > b.allocation ? -1 : 1,
    };

    const toggleIncludeNonBillable = () => setIncludeNonBillable(!includeNonBillable);

    const checkAllocationPercentageInput = (value) => value < 0 ? 0 : value > 100 ? 100 : value;

    const search = async (startDate, endDate, allocation) => {
        setLoading(true);
        setEmployees(await getAvailableEmployees(token, tenant, { start_date: startDate, end_date: endDate, allocation_percentage: allocation / 100, includeNonBillable }));
        setLoading(false);
    }

    const searchNextWeek = () => {
        const nextWeek = DateTime.fromJSDate(endDate).plus({ week: 1 }).toJSDate();
        setEndDate(nextWeek);
        search(startDate, nextWeek, allocation, includeNonBillable);
    }

    useEffect(() => {
        async function getdata() {
            try {
                await search(startDate, endDate, allocation, includeNonBillable);
                setLoading(false);
            } catch (e) {
                setError(e);
                setLoading(false);
            }
        }
        getdata();
    }, [tenant]);

    useEffect(() => {
        if (error) {
            const status = error.response.status;
            navigate(`/NotAuthenticated/${status}`, {
                state: { prevLocation: location.pathname }
            });
        }
    }, [error]);

    function Table() {
        return (
            <div className="d-flex justify-content-center">
                {loading ? <Spinner className='mt-2' animation="border" /> : <AvailableEmployeeTable employees={employees.sort(sortMap[sort])} searchNextWeek={searchNextWeek} />}
            </div>
        )
    }

    return (
        <Container>
            <Helmet />
            <div className="d-flex justify-content-center align-items-center mb-2">
                <Form className="w-100 d-flex align-items-center" onSubmit={e => { e.preventDefault(); search(startDate, endDate, allocation) }}>
                    <Form.Group className="d-flex justify-content-center align-items-center me-2">
                        <span className="me-2">From:</span>
                        <Form.Control type="date" onChange={e => setStartDate(new Date(e.target.value))} value={DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd')} />
                    </Form.Group>
                    <Form.Group className="d-flex justify-content-center align-items-center me-2">
                        <span className="me-2">To:</span>
                        <Form.Control type="date" min={DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd')} onChange={e => setEndDate(new Date(e.target.value))} value={DateTime.fromJSDate(endDate).toFormat('yyyy-MM-dd')} />
                    </Form.Group>
                    <Form.Group className="d-flex justify-content-center align-items-center me-2">
                        <span className="me-2 flex-shrink-0">Allocation (in %):</span>
                        <Form.Control className="w-50" type="number" min="0" max="100" onChange={e => setAllocation(checkAllocationPercentageInput(e.target.value))} value={allocation} />
                    </Form.Group>
                    <Form.Group className="d-flex justify-content-center align-items-center me-2">
                        <Form.Check className="ms-2 me-2" type="checkbox" onClick={() => toggleIncludeNonBillable()} checked={includeNonBillable}></Form.Check>
                        <span>Include non-billable availablity</span>
                    </Form.Group>
                    <div className="d-flex ms-auto justify-content-center align-items-center">
                        <Button variant="dark" className="me-1" onClick={() => searchNextWeek()}>Go to next week</Button>
                        <Button variant="dark" className="ms-1 me-2" type="submit">Search</Button>
                        <div className="d-flex justify-content-center align-items-center">
                            <span className="me-2">Sort:</span>
                            <SortDropdown sort={sort} setSort={setSort} />
                        </div>
                    </div>
                </Form>
            </div>
            <Table className="mt-3"></Table>
        </Container>
    )
}

export default AvailableEmployees;