import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import { Breadcrumb, Col, Row, Form, Alert, Button } from 'react-bootstrap';
import React from 'react';
import { updateEmployeeAssignment, getProjects, getEmployeeAssignmentDetail, getEmployees, getCustomers } from '../../ApiConnection'
import { formatdateformated, getHelmet } from '../../UtilFunctions';
import { useAuthStore } from '../../stores/AuthStore'
import Select from 'react-select'


const EditEmployeeAssignment = () => {
    const { token, tenant } = useAuthStore();
    let { id } = useParams();
    const [assignment, setAssignment] = React.useState("");
    const [customer, setCustomer] = React.useState({ value: { id: '', name: '' }, label: '' });
    const [employee, setEmployee] = React.useState("");
    const [project, setProject] = React.useState({ value: { id: '', customer: '' }, label: '' });
    const [startDate, setStartDate] = React.useState("");
    const [endDate, setEndDate] = React.useState("");
    const [billable, setBillable] = React.useState(false);
    const [renewalExpected, setRenewalExpected] = React.useState(false);
    const [allocationPercentage, setAllocationPercentage] = React.useState();
    const [error, setError] = React.useState();
    const [customers, setCustomers] = React.useState([]);
    const [employees, setEmployees] = React.useState([]);
    const [projects, setProjects] = React.useState([]);
    const [filteredProjects, setFilteredProjects] = React.useState([]);

    const location = useLocation();
    const navigate = useNavigate();
    const routeChange = (id) => {
        const path = `/assignments/${id}`;
        navigate(path);
    }

    const getFilteredProjects = () => projects.filter(project => customer.value.id == project.value.customer);

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Employee Assignment Edit', 'Make edits on employee assignment', true);
    }

    React.useEffect(() => {
        setFilteredProjects(getFilteredProjects());
        if (project.value.customer !== customer.value.id) setProject(null);
    }, [customer])

    React.useEffect(() => {
        async function getData() {
            try {
                const employees = await getEmployees(token, tenant);
                const employeeArray = [];
                employees.forEach(element => {
                    employeeArray.push({ value: element.id, label: `${element.name} ${element.lastname}` })
                });
                setEmployees(employeeArray);
                const projects = await getProjects(token, tenant);
                const projectArray = [];
                const customerArray = [];
                projects.forEach(project => {
                    if (customerArray.filter(customer => customer.value.id === project.customer) <= 0) customerArray.push({ value: { id: project.customer, name: project.customer_name }, label: project.customer_name });
                    projectArray.push({ value: { id: project.id, customer: project.customer }, label: `${project.name}` });
                });
                setCustomers(customerArray);
                setProjects(projectArray);
                const employeeassignment = await getEmployeeAssignmentDetail(token, tenant, id);
                setCustomer({ value: { id: employeeassignment.customer_id, name: employeeassignment.customer_name }, label: employeeassignment.customer_name });
                setEmployee({value: employeeassignment.employee_id, label: `${employeeassignment.employee_name} ${employeeassignment.employee_lastname}`});
                setProject({value: { id: employeeassignment.project_id, customer: employeeassignment.customer_id }, label: employeeassignment.project_name});
                setStartDate(employeeassignment.start_date);
                setEndDate(employeeassignment.end_date);
                setRenewalExpected(!!+employeeassignment.renewal_expected);
                setBillable(!!+employeeassignment.billable);
                setAllocationPercentage(employeeassignment.allocation_percentage * 100);
                setAssignment(employeeassignment);
            } catch (e) {
                setError(e);
            }
        }
        getData()
    }, []);

    const handleSubmit = async event => {
        event.preventDefault();
        try {
            await updateEmployeeAssignment(token, tenant, id, employee?.value, project?.value?.id, startDate, endDate, renewalExpected, billable, allocationPercentage, !!+assignment.active)
            routeChange(id);
        } catch (error) {
            setError(error.response.data)
        }
    }

    return (
        <div>
            <Helmet />
            <Breadcrumb className="mt-3">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/Assignments" }} >Assignments</Breadcrumb.Item>
                <Breadcrumb.Item active>Edit Employee Assignment</Breadcrumb.Item>
            </Breadcrumb>
            <h5>Edit Employee Assignment</h5>
            {(assignment &&
                <form onSubmit={handleSubmit} >
                    <Form.Group className="mb-3">
                        <Form.Label>Employee <span className='text-danger'>*</span></Form.Label>
                        <Select
                            value={employee}
                            onChange={setEmployee}
                            options={employees}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Customer <span className='text-danger'>*</span></Form.Label>
                        <Select
                            value={customer}
                            onChange={setCustomer}
                            options={customers}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Project <span className='text-danger'>*</span></Form.Label>
                        <Select
                            value={project}
                            onChange={setProject}
                            options={filteredProjects}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Start Date <span className='text-danger'>*</span></Form.Label>
                        <Form.Control onChange={e => setStartDate(e.target.value)} value={formatdateformated(startDate)} type="date" required />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>End Date <span className='text-danger'>*</span></Form.Label>
                        <Form.Control onChange={e => setEndDate(e.target.value)} value={formatdateformated(endDate)} type="date" required />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Check type="switch" onChange={e => setRenewalExpected(e.target.checked)} checked={renewalExpected} label="Renewal Expected" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Check type="switch" onChange={e => setBillable(e.target.checked)} checked={billable} label="Billable" />
                    </Form.Group>
                    <Form.Group className="mb-3" as={Row}>
                        <Col xs="2">
                            <Form.Label>Allocation percentage</Form.Label>
                        </Col>
                        <Col xs="1">
                            <Form.Control onChange={e => setAllocationPercentage(e.target.value)} value={allocationPercentage} />
                        </Col>
                        <Col xs="9">
                            <Form.Range
                                value={allocationPercentage}
                                onChange={e => setAllocationPercentage(e.target.value)}
                            />
                        </Col>
                    </Form.Group>
                    {error &&
                        <Alert className='mb-3' variant="danger">
                            {error}
                        </Alert>
                    }
                    <Button variant="dark" type="submit">Edit employee assignment</Button>
                </form>
            )}
        </div>
    );
}

export default EditEmployeeAssignment;
