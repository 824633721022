import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../UtilFunctions";

const CustomerTable = ({customers}) => {
    const navigate = useNavigate();
    const routeChange = (uuid) => {
        const path = `/customers/${uuid}`;
        navigate(path);
    }

    return (
        <Table striped bordered hover className='mt-2'>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Assignments</th>
                    <th>Track contact activity</th>
                    <th>Active</th>
                </tr>
            </thead>
            <tbody>
                {customers.map(arrayData => {
                    return (
                        <tr onClick={() => routeChange(arrayData.id)} style={{cursor: 'pointer'}}>
                            <td>{arrayData.name}</td>
                            <td>{arrayData.activeAssignments}</td>
                            <td>{arrayData.track_contact_activity ? 'Yes' : 'No'}</td>
                            <td>{arrayData.active ? 'Yes' : 'No'}</td>
                        </tr>
                    )
                }
                )}
            </tbody>
        </Table>
    );
}

export default CustomerTable;