import { useEffect, useState } from "react";
import { Breadcrumb, Button, Form, Spinner, Table, Toast } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { addRoles, getAvailableRolesOfTenant, getRolesOfUser, getTenants } from "../../../ApiConnection";
import { useAuthStore } from "../../../stores/AuthStore";
import Select from 'react-select'
import { getHelmet } from "../../../UtilFunctions";

const CreateRolesAnyTenant = () => {
    const { token } = useAuthStore();
    const { username } = useParams();
    const [tenants, setTenants] = useState([]);
    const [selectedTenant, setSelectedTenant] = useState("");
    const [availableRoles, setAvailableRoles] = useState([]);
    const [existingRoles, setExistingRoles] = useState([]);
    const [roles, setRoles] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingRoles, setLoadingRoles] = useState(true);
    const [error, setError] = useState();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      async function getData() {
        try {
          setTenants(await getTenants(token));
          setLoading(false);
          setLoadingRoles(false);
        } catch (error) {
          setError(error);
          setLoading(false);
        }
      }
      getData();
    }, []);

    useEffect(() => {
      async function getRoleData() {
        try {
            setLoadingRoles(true);
            setRoles([]);
            setExistingRoles((await getRolesOfUser(token, selectedTenant.value, username)).map(role => role.role_name));
            setAvailableRoles(await getAvailableRolesOfTenant(token, selectedTenant.value));
            setLoadingRoles(false);
        } catch (error) {
            setError(error);
            setLoadingRoles(false);
        }
      }
      if (selectedTenant) getRoleData();
    }, [selectedTenant]);

    useEffect(() => {
      if (error?.response.status === 401 || error?.response.data === 403)
        navigate(`/NotAuthenticated/${error.response.status}`, {
          state: { prevLocation: location.pathname },
        });
    }, [error]);

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        try {
          if (roles.length > 0) {
            await addRoles(token, selectedTenant.value, username, roles.filter(role => !existingRoles.includes(role)));
            navigate(`/admin-panel/tenants/${selectedTenant.value}/users/${username}/roles`);
          } else setShowToast(true);
        } catch (e) {
          setError(e);
        }
    };

    const selectTenant = (data) => {
        setSelectedTenant(data)
    }

    const handleSelect = (role) => setRoles([...new Set([...roles, role])]);

    const SelectedTable = () => (
        <Table striped bordered hover className="mt-2">
          <thead>
            <tr>
              <th>role</th>
              <th>remove</th>
            </tr>
          </thead>
          <tbody>
            {roles.map((role) => (
              <tr>
                <td>{role}</td>
                <td>
                  <Button
                    onClick={() =>
                      setRoles(roles.filter((selected) => selected !== role))
                    }
                  >
                    Remove
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
    );

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Roles Creation For Any Tenant', 'Creation of roles for any tenant', true);
    }

    return (
      <div>
        <Helmet />
        <Breadcrumb>
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: "/admin-panel/users" }}
          >
            Users
          </Breadcrumb.Item>
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: `/admin-panel/users/${username}` }}
          >
            {username}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Add Roles</Breadcrumb.Item>
        </Breadcrumb>

        <Form onSubmit={handleSubmit} className="d-flex flex-column align-items-center">
          {loading ? (
            <Spinner className="mt-2" animation="border" />
          ) : (
            [
              <Form.Group className="mb-3 w-100">
                <Form.Label>Tenant</Form.Label>
                <Select
                  value={selectedTenant}
                  onChange={selectTenant}
                  options={tenants.map((ten) => ({
                    value: ten.id,
                    label: ten.name,
                  }))}
                />
              </Form.Group>,
              selectedTenant === "" ? (
                <h2>Select a tenant first</h2>
              ) : loadingRoles ? (
                <Spinner className="mt-2" animation="border" />
              ) : (
                [
                  <Form.Group className="mb-3 w-100">
                    <Form.Label>Roles</Form.Label>
                    <Form.Select
                      onChange={(e) => handleSelect(e.target.value)}
                      value=''
                    >
                      {[...availableRoles, ""]
                        .filter(
                          (availableRole) =>
                            !roles.includes(availableRole) &&
                            !existingRoles.includes(availableRole)
                        )
                        .map((availableRole) => (
                          <option value={availableRole}>{availableRole}</option>
                        ))}
                    </Form.Select>
                  </Form.Group>,
                  <SelectedTable></SelectedTable>,
                  <Button className="w-25" variant="dark" type="submit">
                    Add roles
                  </Button>,
                ]
              ),
            ]
          )}
        </Form>
        <Toast bg="warning" autohide show={showToast} onClose={() => setShowToast(false)}>
            <Toast.Body>
                You must select at least 1 role.
            </Toast.Body>
        </Toast>
      </div>
    );
}

export default CreateRolesAnyTenant;