import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../UtilFunctions";

const EmployeeAssignmentTable = ({assignments}) => {

    const navigate = useNavigate();
    const routeChange = (id) => {
        const path = `/assignments/${id}`;
        navigate(path);
    }

    return (
        <Table striped bordered hover className='mt-2'>
            <thead>
                <tr>
                    <th>Employee name</th>
                    <th>Customer</th>
                    <th>Project</th>
                    <th>Start date</th>
                    <th>End date</th>
                    <th>Renewal expected</th>
                    <th>Allocation percentage</th>
                    <th>Billable</th>
                </tr>
            </thead>
            <tbody>
                {assignments.map(arrayData => {
                    return (
                        <tr onClick={() => routeChange(arrayData.id)} style={{cursor: 'pointer'}}>
                            <td>{arrayData.employee_name} {arrayData.employee_lastname}</td>
                            <td>{arrayData.customer_name}</td>
                            <td>{arrayData.project_name}</td>
                            <td>{formatDate(arrayData.start_date)}</td>
                            <td>{formatDate(arrayData.end_date)}</td>
                            <td>{arrayData.renewal_expected ? 'Yes' : 'No'}</td>
                            <td>{Math.round(arrayData.allocation_percentage * 100)}%</td>
                            <td>{arrayData.billable ? 'Yes' : 'No'}</td>
                        </tr>
                    )
                }
                )}
            </tbody>
        </Table>
    );
}

export default EmployeeAssignmentTable;