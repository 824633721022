import React from 'react';
import { Form, Button, Breadcrumb, Alert } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { addCustomer } from '../../ApiConnection';
import { useAuthStore } from '../../stores/AuthStore'
import { getHelmet } from '../../UtilFunctions';

const CreateCustomer = () => {
    const { token, tenant } = useAuthStore();
    const [name, setName] = React.useState('');
    const [error, setError] = React.useState();


    const navigate = useNavigate();
    const routeChange = (uuid) => {
        const path = `/customers/${uuid}`;
        navigate(path);
    }

    const handleSubmit = async event => {
        event.preventDefault();


        try {
            const obj = await addCustomer(token, tenant, name)
            routeChange(obj.data.id);
        } catch (error) {
            setError(error)
        }
    }

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Customer Creation', 'Creation of customer', true);
    }

    return (
        <div>
            <Helmet />
            <Breadcrumb className="mt-3">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/customers" }} >Customers</Breadcrumb.Item>
                <Breadcrumb.Item active>Create Customer</Breadcrumb.Item>
            </Breadcrumb>
            <form onSubmit={handleSubmit} >
                <Form.Group className="mb-3">
                    <Form.Label>Name <span className='text-danger'>*</span></Form.Label>
                    <Form.Control onChange={e => setName(e.target.value.trim() === '' ? e.target.value.trim() : e.target.value.trimStart())} value={name} required />
                </Form.Group>
                {(error &&
                    <Alert className='mb-3' variant="danger">
                        {error}
                    </Alert>)}
                <Button variant="dark" type="submit">Add Customer</Button>
            </form>
        </div>
    );
}

export default CreateCustomer;
