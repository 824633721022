import { useParams, Link, useNavigate } from 'react-router-dom'
import { Breadcrumb, Form, Alert, Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { getEmployeeCost, updateEmployeeCost } from '../../ApiConnection'
import { getHelmet } from '../../UtilFunctions';
import { useAuthStore } from '../../stores/AuthStore';

const EditEmployeeCost = () => {
    const { token, tenant } = useAuthStore();
    const { id, costid } = useParams();
    const [cost, setCost] = useState(null);
    const [from, setFrom] = useState(new Date());
    const [amount, setAmount] = useState(0);
    const [active, setActive] = useState(true);
    const [error, setError] = useState();
    

    const navigate = useNavigate();

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Employee Cost Edit', 'Make edits to employee cost', true);
    }

    useEffect(() => {
        async function getData() {
            const cost = await getEmployeeCost(token, tenant, costid);
            setFrom(new Date(cost.from_date));
            setAmount(cost.amount);
            setActive(cost.active === 1 ? true : false)
            setCost(cost);
        }
        getData()
    }, [token, tenant]);

    const handleSubmit = async event => {
        event.preventDefault();

        try {
            await updateEmployeeCost(token, tenant, costid, from, amount, active)
            navigate(`/employees/${id}`);
        } catch (error) {
            setError(error.response.data)
        }
    }

    return (
        <div>
            <Helmet />
            <Breadcrumb className="mt-3">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/employees" }} >Employees</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/employees/${id}` }}>Employee Details</Breadcrumb.Item>
                <Breadcrumb.Item active>Edit Employee Cost</Breadcrumb.Item>
            </Breadcrumb>
            <h5>Edit Employee Cost</h5>
            {(cost &&
                <form onSubmit={handleSubmit} >
                    <Form.Group className='mb-3'>
                        <Form.Label>From <span className='text-danger'>*</span></Form.Label>
                        <Form.Control onChange={e => setFrom(new Date(e.target.value))} type="date" value={DateTime.fromJSDate(from).toFormat('yyyy-MM-dd')} required/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Amount <span className='text-danger'>*</span></Form.Label>
                        <Form.Control type="number" onChange={e => setAmount(e.target.value.trim() === '' && !isNaN(e.target.value) ? e.target.value.trim() : e.target.value.trimStart())} value={amount} required />
                    </Form.Group>
                    {error &&
                        <Alert className={`mb-3 ${error ? 'd-none': ''}`} variant="danger">
                            {error}
                        </Alert>
                    }
                    <Button variant="dark" type="submit">Edit employee cost</Button>
                </form>
            )}
        </div>
    );
}

export default EditEmployeeCost;
