import { Alert, Button, Modal } from "react-bootstrap";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { addContact } from "../../ApiConnection";
import { useAuthStore } from "../../stores/AuthStore";
import React from "react";

const CreateContactModal = ({ show, toggle, fetchContacts }) => {
  const { token, tenant } = useAuthStore();
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await addContact(token, tenant, name, lastname, email);
      await fetchContacts();
      toggle();
    } catch (error) {
      setError(error.response.data);
    }
  };

  return (
    
      <Modal onShow={() => setError(null)} centered show={show} onHide={() => toggle()}>
        <Modal.Header closeButton>
          <Modal.Title>Create a new contact</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>First name</Form.Label>
              <Form.Control
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Last name</Form.Label>
              <Form.Control
                onChange={(e) => setLastname(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>
            {error && (
              <Alert
                className={`mb-3 ${!error ? "d-none" : ""}`}
                variant="danger"
              >
                {error}
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => toggle()}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              Create
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    
  );
};

export default CreateContactModal;
