import { useEffect, useState } from "react";
import { Breadcrumb, Button, Container, Modal, Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { adminDeleteRoles, adminGetRolesOfUser } from "../../../ApiConnection";
import { useAuthStore } from "../../../stores/AuthStore";
import { getHelmet } from "../../../UtilFunctions";
import RoleTable from "../../admin/role/RoleTable";

const Roles = () => {
  const { token } = useAuthStore();
  const { id, username } = useParams();
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  const handleDelete = async (selectedRoles) => {
    await adminDeleteRoles(token, id, username, selectedRoles);
    navigate(0);
  };

  const Helmet = () => {
    return getHelmet('Cloudway Accounts - Roles Table', 'Listing of all roles of a user per tenant', true);
  }

  const addToSelectedRoles = (role) =>
    setSelectedRoles([...selectedRoles, role]);

  const removeFromSelectedRoles = (role) =>
    setSelectedRoles(selectedRoles.filter((selected) => selected !== role));

  const getData = async () => {
    try {
      setRoles((await adminGetRolesOfUser(token, id, username)).map(role => ({...role, checked: false})));
      setLoading(false);
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const Table = () =>
    error ? (
      navigate(`/NotAuthenticated/${error.response.status}`, {
        state: { prevLocation: location.pathname },
      })
    ) : (
      <div className="d-flex justify-content-center">
        {loading ? (
          <Spinner className="mt-2" animation="border" />
        ) : (
          <RoleTable
            roles={roles.sort((a, b) => a.role_name - b.role_name)}
            addToSelectedRoles={addToSelectedRoles}
            removeFromSelectedRoles={removeFromSelectedRoles}
          />
        )}
      </div>
    );

  return (
    <Container>
      <Helmet />
      <div className="d-flex flex-column">
        <Breadcrumb className="mt-2">
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: `/manage/${id}/users` }}
          >
            Users
          </Breadcrumb.Item>
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: `/manage/${id}/users/${username}` }}
          >
            {username}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Roles</Breadcrumb.Item>
        </Breadcrumb>
        <div className="d-flex ms-auto justify-content-center align-items-center">
          <Button variant="dark" onClick={() => setShow(true)}>
            Delete
          </Button>
          <Button
            className="ms-2"
            variant="dark"
            onClick={() =>
              navigate(
                `/manage/${id}/users/${username}/roles/create`
              )
            }
          >
            Add roles
          </Button>
        </div>
      </div>
      <Table></Table>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Roles</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure, you want to delete these Roles!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button
            variant="dark"
            onClick={() => {
              handleDelete(selectedRoles);
            }}
          >
            Delete roles
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Roles;
