import { computed, makeObservable, action, observable, runInAction } from "mobx"
import React, { createContext, useContext } from "react";
import { persist } from 'mobx-persist';
import { checkToken } from "../UtilFunctions";

export class AuthStore {
    username = '';
    token = '';
    refreshToken = '';
    tenant = '';
    roles = [];
    userIdentifier = '';
    tenants = [];
    checkAllocationData = [];
    monthsBelowAllocationThreshold = 0;
    monthsBelowFinancePercentage = 0;
    isLoggedIn = false;
    showAllocationAlarm = false;
    showFinanceAlarm= false;
    tenantAllocationAlarmThresholdSetting = 0;
    tenantAllocationAlarmMonthsSetting = 0;
    tenantFinanceAlarmPercentageSetting = 0;
    tenantFinanceAlarmMonthsSetting = 0;

    constructor() {
        makeObservable(this, {
            username: observable,
            token: observable,
            tenant: observable,
            roles: observable,
            refreshToken: observable,
            userIdentifier: observable,
            isLoggedIn: observable,
            monthsBelowAllocationThreshold: observable,
            monthsBelowFinancePercentage: observable,
            showAllocationAlarm: observable,
            showFinanceAlarm: observable,
            tenantAllocationAlarmThresholdSetting: observable,
            tenantAllocationAlarmMonthsSetting: observable,
            tenantFinanceAlarmPercentageSetting: observable,
            tenantFinanceAlarmMonthsSetting: observable,
            setUserIdentifier: action,
            setTenant: action,
            setRoles: action,
            setTenants: action,
            setCheckAllocationData: action,
            setCheckFinanceData: action,
            setMonthsBelowAllocationThreshold: action,
            setMonthsBelowFinancePercentage: action,
            setAuthToken: action,
            setShowAllocationAlarm: action,
            setShowFinanceAlarm: action,
            checkToken: action,
            setRefreshToken: action,
            setCurrentUser: action,
            currentUser: computed,
            logoutUser: action,
            setIsLoggedIn: action,
            isSuperUser: computed,
            actionnableTenants: computed,
            setTenantAllocationAlarmThresholdSetting: action,
            setTenantAllocationAlarmMonthsSetting: action,
            setTenantFinanceAlarmPercentageSetting: action,
            setTenantFinanceAlarmMonthsSetting: action,
        });
    }

    checkToken = async (token) => {
        return await checkToken(token);
    }

    setAuthToken = async (token) => {
        const verifiedToken = await this.checkToken(token);
        runInAction(() => this.token = verifiedToken);
    }

    setRefreshToken = (token) => {
        this.refreshToken = token;
    }

    logoutUser = () => {
        this.show = false;
        this.username = '';
        this.token = '';
        this.refreshToken = '';
        this.tenant = '';
        this.checkAllocationData = [];
        this.monthsBelowAllocationThreshold = 0;
        this.monthsBelowFinancePercentage = 0;
        this.tenants = [];
        this.isLoggedIn = false;
    }

    setShowAllocationAlarm = (show) => {
         this.showAllocationAlarm = show;
    }

    setShowFinanceAlarm = (show) => {
        this.showFinanceAlarm = show;
   }

    setCurrentUser = (user) => {
        this.username = user;
    }

    setMonthsBelowAllocationThreshold = (monthsBelowAllocationThreshold) => {
        this.monthsBelowAllocationThreshold = monthsBelowAllocationThreshold;
    }

    setMonthsBelowFinancePercentage = (monthsBelowFinancePercentage) => {
        this.monthsBelowFinancePercentage = monthsBelowFinancePercentage;
    }

    setCheckAllocationData = (checkAllocationData) => {
        this.checkAllocationData = checkAllocationData;
    }

    setCheckFinanceData = (checkFinanceData) => {
        this.checkFinanceData = checkFinanceData;
    }

    setTenant = (tenant) => {
        this.tenant = tenant;
        this.showAllocationAlarm = false;
        this.showFinanceAlarm = false;
    }

    setUserIdentifier = (userIdentifier) => {
        this.userIdentifier = userIdentifier;
    }

    setTenants = (tenants) => {
        this.tenants = tenants;
        if(this.tenant === '') {
            this.tenant = tenants[0]?.id;
        }
    }

    setRoles = (roles) => {
        console.log(roles);
        this.roles = roles;
    }

    setIsLoggedIn = (loggedIn) => this.isLoggedIn = loggedIn;

    setTenantAllocationAlarmThresholdSetting = (tenantAllocationAlarmThresholdSetting) => this.tenantAllocationAlarmThresholdSetting = tenantAllocationAlarmThresholdSetting;

    setTenantAllocationAlarmMonthsSetting = (tenantAllocationAlarmMonthsSetting) => this.tenantAllocationAlarmMonthsSetting = tenantAllocationAlarmMonthsSetting;

    setTenantFinanceAlarmPercentageSetting = (tenantFinanceAlarmPercentageSetting) => this.tenantFinanceAlarmPercentageSetting = tenantFinanceAlarmPercentageSetting;

    setTenantFinanceAlarmMonthsSetting = (tenantFinanceAlarmMonthsSetting) => this.tenantFinanceAlarmMonthsSetting = tenantFinanceAlarmMonthsSetting;

    get currentUser() {
        return `${this.username}`;
    }

    get isSuperUser() {
        return this.tenants.some(ten => ten.id === '00000000-0000-0000-0000-000000000001');
    }

    get actionnableTenants() {
        return this.tenants.filter(ten => ten.id !== '00000000-0000-0000-0000-000000000001');
    }
}

const persistSchema = {
    monthsBelowAllocationThreshold: true,
    monthsBelowFinancePercentage: true,
    checkAllocationData: {
        type: 'list',
    },
    checkFinanceData: {
        type: 'list',
    },
    tenants: {
        type: 'list',
    },
    roles: {
        type: 'list',
    },
    showAllocationAlarm: true,
    showFinanceAlarm: true,
    username: true,
    token: true,
    refreshToken: true,
    tenant: true,
    userIdentifier: true,
    isLoggedIn: true,
    tenantAllocationAlarmThresholdSetting: true,
    tenantAllocationAlarmMonthsSetting: true,
    tenantFinanceAlarmPercentageSetting: true,
    tenantFinanceAlarmMonthsSetting: true,
}

const AuthContext = createContext(null);

export const useAuthStore = () => useContext(AuthContext);

export const authStore = persist(persistSchema)(new AuthStore());

export const AuthStoreProvider = ({ children, store }) => {
    return (
        <AuthContext.Provider value={store}>
            {children}
        </AuthContext.Provider>
    );
}
