import React from 'react';
import { Row, Col, Form, Button, Breadcrumb, Alert } from 'react-bootstrap'
import {Link, useNavigate, useLocation, useParams} from 'react-router-dom'
import { addWorkPeriod } from '../../ApiConnection';
import { useAuthStore } from '../../stores/AuthStore'
import { getHelmet } from '../../UtilFunctions';
import {DateTime} from "luxon";

const CreateWorkPeriod = () => {
    const { id } = useParams();
    const { token, tenant } = useAuthStore();
    const [dateFrom, setDateFrom] = React.useState(new Date());
    const [dateTo, setDateTo] = React.useState(new Date());
    const [workPeriodType, setworkPeriodType] = React.useState('');
    const [weeklyAllocationPercentage, setweeklyAllocationPercentage] = React.useState(0);
    const [mondayAllocationPercentage, setMondayAllocationPercentage] = React.useState(0);
    const [tuesdayAllocationPercentage, setTuesdayAllocationPercentage] = React.useState(0);
    const [wednesdayAllocationPercentage, setWednesdayAllocationPercentage] = React.useState(0);
    const [thursdayAllocationPercentage, setThursdayAllocationPercentage] = React.useState(0);
    const [fridayAllocationPercentage, setFridayAllocationPercentage] = React.useState(0);
    const [detailedAllocationPercentage, setDetailedAllocationPercentage] = React.useState(0);
    const [error, setError] = React.useState();

    const navigate = useNavigate();
    const routeChange = () => {
        const path = `/assignments/${id}`;
        navigate(path);
    }

    const handleSubmit = async event => {
        event.preventDefault();

        try {
            const result = await addWorkPeriod(token, tenant, id, dateFrom, dateTo, workPeriodType, weeklyAllocationPercentage,
                mondayAllocationPercentage, tuesdayAllocationPercentage, wednesdayAllocationPercentage, thursdayAllocationPercentage,
                fridayAllocationPercentage, detailedAllocationPercentage);
            routeChange();
        } catch (error) {
            setError(error.message)
        }
    }

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Work Period Creation', 'Creation of work period', true);
    }

    return (
        <div>
            <Helmet />
            <Breadcrumb className="mt-3">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/assignments" }} >Assignments</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/assignments/${id}` }} >Employee Assignment Details</Breadcrumb.Item>
                <Breadcrumb.Item active>Create Work Period</Breadcrumb.Item>
            </Breadcrumb>
            <form onSubmit={handleSubmit} >
                <Form.Group className='mb-3'>
                    <Form.Label>Date from <span className='text-danger'>*</span></Form.Label>
                    <Form.Control onChange={e => setDateFrom(new Date(e.target.value))} type="date" value={DateTime.fromJSDate(dateFrom).toFormat('yyyy-MM-dd')} required/>
                </Form.Group>
                <Form.Group className='mb-3'>
                    <Form.Label>Date to <span className='text-danger'>*</span></Form.Label>
                    <Form.Control onChange={e => setDateTo(new Date(e.target.value))} type="date" value={DateTime.fromJSDate(dateTo).toFormat('yyyy-MM-dd')} required/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Work period type <span className='text-danger'>*</span></Form.Label>
                    <Form.Check
                        label= {<div><p className="d-inline">Weekly</p></div>}
                        name="workPeriodType"
                        type='radio'
                        onChange={e => setworkPeriodType("WEEKLY")}
                        id={`inline-radio-1`}
                        required
                    />
                    <Form.Check
                        label= {<div><p className="d-inline">Daily</p></div>}
                        name="workPeriodType"
                        type='radio'
                        onChange={e => setworkPeriodType("DAILY")}
                        id={`inline-radio-2`}
                        required
                    />
                    <Form.Check
                        name= "workPeriodType"
                        label= {<div><p className="d-inline">Detailed</p></div>}
                        type={'radio'}
                        onChange={e => setworkPeriodType("DETAILED")}
                        id={`inline-radio-3`}
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-3" as={Row}>
                    <Form.Label>Allocation percentages<span className='text-danger'>*</span></Form.Label>
                    <Col xs="2">
                        <Form.Label>Weekly</Form.Label>
                    </Col>
                    <Col xs="1">
                        <Form.Control value={weeklyAllocationPercentage} />
                    </Col>
                    <Col xs="9">
                        <Form.Range
                            value={weeklyAllocationPercentage}
                            onChange={e => setweeklyAllocationPercentage(e.target.value)}
                        />
                    </Col>
                </Form.Group>
                <Form.Group className="mb-3" as={Row}>
                    <Col xs="2">
                        <Form.Label>Monday</Form.Label>
                    </Col>
                    <Col xs="1">
                        <Form.Control value={mondayAllocationPercentage} />
                    </Col>
                    <Col xs="9">
                        <Form.Range
                            value={mondayAllocationPercentage}
                            onChange={e => setMondayAllocationPercentage(e.target.value)}
                        />
                    </Col>
                </Form.Group>
                <Form.Group className="mb-3" as={Row}>
                    <Col xs="2">
                        <Form.Label>Tuesday</Form.Label>
                    </Col>
                    <Col xs="1">
                        <Form.Control value={tuesdayAllocationPercentage} />
                    </Col>
                    <Col xs="9">
                        <Form.Range
                            value={tuesdayAllocationPercentage}
                            onChange={e => setTuesdayAllocationPercentage(e.target.value)}
                        />
                    </Col>
                </Form.Group>
                <Form.Group className="mb-3" as={Row}>
                    <Col xs="2">
                        <Form.Label>Wednesday</Form.Label>
                    </Col>
                    <Col xs="1">
                        <Form.Control value={wednesdayAllocationPercentage} />
                    </Col>
                    <Col xs="9">
                        <Form.Range
                            value={wednesdayAllocationPercentage}
                            onChange={e => setWednesdayAllocationPercentage(e.target.value)}
                        />
                    </Col>
                </Form.Group>
                <Form.Group className="mb-3" as={Row}>
                    <Col xs="2">
                        <Form.Label>Thursday</Form.Label>
                    </Col>
                    <Col xs="1">
                        <Form.Control value={thursdayAllocationPercentage} />
                    </Col>
                    <Col xs="9">
                        <Form.Range
                            value={thursdayAllocationPercentage}
                            onChange={e => setThursdayAllocationPercentage(e.target.value)}
                        />
                    </Col>
                </Form.Group>
                <Form.Group className="mb-3" as={Row}>
                    <Col xs="2">
                        <Form.Label>Friday</Form.Label>
                    </Col>
                    <Col xs="1">
                        <Form.Control value={fridayAllocationPercentage} />
                    </Col>
                    <Col xs="9">
                        <Form.Range
                            value={fridayAllocationPercentage}
                            onChange={e => setFridayAllocationPercentage(e.target.value)}
                        />
                    </Col>
                </Form.Group>
                <Form.Group className="mb-3" as={Row}>
                    <Col xs="2">
                        <Form.Label>Detailed</Form.Label>
                    </Col>
                    <Col xs="1">
                        <Form.Control value={detailedAllocationPercentage} />
                    </Col>
                    <Col xs="9">
                        <Form.Range
                            value={detailedAllocationPercentage}
                            onChange={e => setDetailedAllocationPercentage(e.target.value)}
                        />
                    </Col>
                </Form.Group>
                {(error &&
                    <Alert className='mb-3' variant="danger">
                        {error}
                    </Alert>)}
                <Button variant="dark" type="submit">Add work period</Button>
            </form>
        </div>
    );
}

export default CreateWorkPeriod;
