import { useParams, Link, useNavigate } from 'react-router-dom'
import { Breadcrumb, Form, Alert, Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { getContact, updateContact } from '../../ApiConnection'
import { useAuthStore } from '../../stores/AuthStore';
import { getHelmet } from '../../UtilFunctions';

const EditContact = () => {
    const { token, tenant } = useAuthStore();
    const { id } = useParams();
    const [contact, setContact] = useState(null);
    const [name, setName] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [active, setActive] = useState(true);
    const [error, setError] = useState();

    const navigate = useNavigate();

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Contact Edit', 'Make edits to contact', true);
    }

    useEffect(() => {
        async function getData() {
            const contact = await getContact(token, tenant, id);
            setName(contact.name);
            setLastname(contact.lastname);
            setEmail(contact.email);
            setActive(contact.active === 1 ? true : false)
            setContact(contact);
        }
        getData()
    }, [token, tenant]);

    const handleSubmit = async event => {
        event.preventDefault();

        try {
            await updateContact(token, tenant, id, name, lastname, email, active);
            navigate(`/contacts/${id}`);
        } catch (error) {
            setError(error.response.data)
        }
    };

    return (
        <div>
            <Helmet />
            <Breadcrumb className="mt-3">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/contacts" }} >Contacts</Breadcrumb.Item>
                <Breadcrumb.Item active>Edit Contact</Breadcrumb.Item>
            </Breadcrumb>
            <h5>Edit Contact</h5>
            {(contact &&
                <form onSubmit={handleSubmit} >
                    <Form.Group className="mb-3">
                        <Form.Label>First name <span className='text-danger'>*</span></Form.Label>
                        <Form.Control type='text' onChange={e => setName(e.target.value.trim() === '' ? e.target.value.trim() : e.target.value.trimStart())} value={name} required />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Last name <span className='text-danger'>*</span></Form.Label>
                        <Form.Control type='text' onChange={e => setLastname(e.target.value.trim() === '' ? e.target.value.trim() : e.target.value.trimStart())} value={lastname} reqiured />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Email <span className='text-danger'>*</span></Form.Label>
                        <Form.Control onChange={e => setEmail(e.target.value.trim() === '' ? e.target.value.trim() : e.target.value.trimStart())} value={email} required />
                    </Form.Group>
                    {error &&
                        <Alert className={`mb-3 ${error ? 'd-none': ''}`} variant="danger">
                            {error}
                        </Alert>
                    }
                    <Button variant="dark" type="submit">Edit contact</Button>
                </form>
            )}
        </div>
    );
}

export default EditContact;