import { useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { updateTenantAlarmSettings } from "../ApiConnection";
import { useAuthStore } from "../stores/AuthStore";

const Settings = () => {
    const { token, tenant, tenantAllocationAlarmThresholdSetting, tenantAllocationAlarmMonthsSetting, setTenantAllocationAlarmThresholdSetting, setTenantAllocationAlarmMonthsSetting, setTenantFinanceAlarmMonthsSetting, setTenantFinanceAlarmPercentageSetting, tenantFinanceAlarmPercentageSetting, tenantFinanceAlarmMonthsSetting, } = useAuthStore();
    const [threshold, setThreshold] = useState(tenantAllocationAlarmThresholdSetting);
    const [months, setMonths] = useState(tenantAllocationAlarmMonthsSetting);
    const [percentage, setPercentage] = useState(tenantFinanceAlarmPercentageSetting);
    const [financeMonths, setFinanceMonths] = useState(tenantFinanceAlarmMonthsSetting);
    const [error, setError] = useState(null);


    const handleSubmit = async event => {
        event.preventDefault();

        try {
            await updateTenantAlarmSettings(token, tenant, threshold, months, financeMonths, percentage);
            setTenantAllocationAlarmThresholdSetting(threshold);
            setTenantAllocationAlarmMonthsSetting(months);
            setTenantFinanceAlarmMonthsSetting(financeMonths);
            setTenantFinanceAlarmPercentageSetting(percentage);
        } catch (error) {
            setError(error.response.data);
        }
    }

    return (
        <div className="w-100 d-flex flex-column justify-content-center align-items-center">
            <h1>Settings</h1>

            <form className="d-flex flex-column justify-content-center align-items-center" onSubmit={handleSubmit} >
                <Form.Group className="mb-3">
                    <Form.Label>Allocation Alarm threshold</Form.Label>
                    <Form.Control type="number" min="0" max="100" onChange={e => setThreshold(e.target.value)} value={threshold} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Allocation Alarm Months</Form.Label>
                    <Form.Control type="number" min="0" max="12" onChange={e => setMonths(e.target.value)} value={months} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Finance Alarm Percentage</Form.Label>
                    <Form.Control type="number" min="0" onChange={e => setPercentage(e.target.value)} value={percentage} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Finance Alarm Months</Form.Label>
                    <Form.Control type="number" min="0" max="12" onChange={e => setFinanceMonths(e.target.value)} value={financeMonths} />
                </Form.Group>
                
                {(error &&
                    <Alert className='mb-3' variant="danger">
                        {error}
                    </Alert>)}
                <Button variant="dark" type="submit">Apply</Button>
            </form>
        </div>
    )
}

export default Settings;