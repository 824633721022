import React, { useState } from "react";
import { Button, Modal, Container, Card, ListGroup, Col, Row } from "react-bootstrap";
import { Clock, Trash3, Pencil, Save, X } from "react-bootstrap-icons";
import { deleteComment, updateComment } from "../../ApiConnection";
import { useAuthStore } from "../../stores/AuthStore";
import { calculateTimeBetween } from "../../UtilFunctions";
import { observer } from "mobx-react";

const YourComment = ({ comment, fetch }) => {
    const { token, tenant } = useAuthStore();
    const [show, setShow] = useState(false);
    const [editing, setEditing] = useState(false);
    const [currentText, setCurrentText] = useState(comment.text);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleDelete = async () => {
        await deleteComment(token, tenant, comment.id);
        handleClose();
        await fetch();
    };
    const handleUpdate = async () => {
        await updateComment(token, tenant, comment.id, currentText, comment.active ? true : false);
        setEditing(false);
        await fetch();
        setCurrentText(comment.text);
    };

    return (
        <ListGroup.Item>
            <Container fluid>
                <Row>
                    <Col>
                        <h6 className="fw-bold mb-1">{comment.created_by}</h6>
                        <p className="mb-3">
                            {calculateTimeBetween(comment.created_at)}                 {comment.created_at !== comment.modified_at ? (
                                <small className="fs-6 ms-2 text-muted">(edited)</small>
                            ) : (
                                ""
                            )}
                        </p>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <div>
                            {!editing ? (
                                <div>
                                    <Pencil
                                        className="fs-5 me-2"
                                        style={{ cursor: "pointer" }}
                                        color="grey"
                                        onClick={() => setEditing(true)}
                                    />
                                    <Trash3 className="fs-5 me-2"
                                        style={{ cursor: "pointer" }}
                                        color="gray"
                                        onClick={() => handleShow()} />
                                </div>
                            ) : (
                                <div>
                                    <X
                                        className="fs-5 me-2"
                                        style={{ cursor: "pointer" }}
                                        color="gray"
                                        onClick={() => setEditing(false)}
                                    />
                                    <Save
                                        className="fs-5 me-2"
                                        style={{ cursor: "pointer" }}
                                        color="gray"
                                        onClick={() => handleUpdate()}
                                    />
                                    <Trash3 className="fs-5 me-2"
                                        style={{ cursor: "pointer" }}
                                        color="gray"
                                        onClick={() => handleShow()} />
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
                {!editing ? (
                    <p className="mb-0">
                        {comment.text}
                    </p>) : (
                    <textarea
                        className="w-100"
                        type="text"
                        value={currentText}
                        onChange={(e) => setCurrentText(e.target.value)}
                    />
                )}
            </Container>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Comment</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure, you want to delete this comment!</Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="dark"
                        onClick={() => {
                            handleDelete();
                        }}
                    >
                        Delete Comment
                    </Button>
                </Modal.Footer>
            </Modal>
        </ListGroup.Item>
    );
};

export default observer(YourComment);
