import { DateTime } from 'luxon';
import React from 'react';
import { useState } from 'react';
import { Form, Button, Breadcrumb, Alert } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { addEmployee } from '../../ApiConnection';
import { useAuthStore } from '../../stores/AuthStore';
import { getHelmet } from '../../UtilFunctions';

const CreateEmployee = () => {
    const { token, tenant } = useAuthStore();
    const [name, setName] = React.useState('');
    const [lastname, setLastname] = React.useState('');
    const [startDate, setStartDate] = useState(DateTime.now().toJSDate());
    const [endDate, setEndDate] = useState(null);
    const [error, setError] = React.useState();


    const navigate = useNavigate();
    const routeChange = (uuid) => {
        const path = `/employees/${uuid}`;
        navigate(path);
    }

    const handleSubmit = async event => {
        event.preventDefault();

        try {
            const result = await addEmployee(token, tenant, name, lastname, startDate, endDate);
            routeChange(result.data.id);
        } catch (error) {
            setError(error.response.data)
        }
    }

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Employee Creation', 'Creation of employee', true);
    }

    return (
        <div>
            <Helmet />
            <Breadcrumb className="mt-3">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/employees" }} >Employees</Breadcrumb.Item>
                <Breadcrumb.Item active>Create Employee</Breadcrumb.Item>
            </Breadcrumb>
            <form onSubmit={handleSubmit} >
                <Form.Group className="mb-3">
                    <Form.Label>First name <span className='text-danger'>*</span></Form.Label>
                    <Form.Control onChange={e => setName(e.target.value.trim() === '' ? e.target.value.trim() : e.target.value.trimStart())} value={name} required />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Name <span className='text-danger'>*</span></Form.Label>
                    <Form.Control onChange={e => setLastname(e.target.value.trim() === '' ? e.target.value.trim() : e.target.value.trimStart())} value={lastname} required />
                </Form.Group>
                <Form.Group className='mb-3'>
                    <Form.Label>Start date <span className='text-danger'>*</span></Form.Label>
                    <Form.Control onChange={e => setStartDate(new Date(e.target.value))} type="date" value={DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd')} required/>
                </Form.Group>
                <Form.Group className='mb-3'>
                    <Form.Label>End date</Form.Label>
                    <div className='d-flex align-items-center'>
                        <Form.Control className='w-75' onChange={e => setEndDate(new Date(e.target.value))} type="date" value={DateTime.fromJSDate(endDate).toFormat('yyyy-MM-dd')} />
                        <a className='link-primary ms-3' role='button' onClick={(() => setEndDate(null))}>reset</a>
                    </div>
                </Form.Group>
                {(error &&
                    <Alert className='mb-3' variant="danger">
                        {error}
                    </Alert>)}
                <Button variant="dark" type="submit">Add employee</Button>
            </form>
        </div>
    );
}

export default CreateEmployee;
