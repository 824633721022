import { Nav, Navbar, NavLink } from "react-bootstrap";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from "react-router-dom"
import React from "react";
import { observer } from "mobx-react";
import { useAuthStore } from "../stores/AuthStore";
import NavigationBarTenant from "./NavigationBarTenant";
import LoginWidget from "./LoginWidget";
import AllocationAlarm from "./AllocationAlarm";
import AllocationError from "./ErrorField";
import CloudwayLogo from '../cloudway_logo_small.svg';
import FinanceAlarm from "./FinanceAlarm";

const Navigationbar = () => {
    const { actionnableTenants } = useAuthStore();

    return (
        <div className="d-flex flex-column justify-content-center align-items-center">
            <Navbar className="w-100" collapseOnSelect expand="sm" bg="dark" variant="dark">
                <Navbar.Brand className="ms-2" as={Link} to="/">
                    <img width="70" height="50" alt="Cloudway logo" src={CloudwayLogo} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" data-bs-target="#navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="container-fluid">
                        <NavLink eventKey="1" as={Link} to="/">Dashboard</NavLink>
                        <NavDropdown title="Employees" id="basic-nav-dropdown">
                            <NavDropdown.Item eventKey="3" as={Link} to="/employees">Employees</NavDropdown.Item>
                            <NavDropdown.Item eventKey="4" as={Link} to="/employees/search">Availability</NavDropdown.Item>
                            <NavDropdown.Item eventKey="5" as={Link} to="/assignments">Assignments</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Customers" id="basic-nav-dropdown">
                            <NavDropdown.Item eventKey="5" as={Link} to="/customers">Customers</NavDropdown.Item>
                            <NavDropdown.Item eventKey="6" as={Link} to="/projects">Projects</NavDropdown.Item>
                            <NavDropdown.Item eventKey="7" as={Link} to="/touchpoints">Customer Touchpoints</NavDropdown.Item>
                            <NavDropdown.Item eventKey="8" as={Link} to="/contacts">Contacts</NavDropdown.Item>
                        </NavDropdown>
                        <div className="ms-auto d-flex justify-content-center align-items-center" >
                            <FinanceAlarm />
                            <AllocationAlarm />
                            {actionnableTenants.length > 0 &&
                                <NavigationBarTenant />
                            }
                            <LoginWidget />
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <AllocationError />
        </div>
    )
}

export default observer(Navigationbar);