import { useAuthStore } from "../../stores/AuthStore";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getHelmet } from "../../UtilFunctions";

const Logout = () => {
    const { logoutUser } = useAuthStore();
    const navigate = useNavigate();

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Logout', 'Logout page', true);
    }

    React.useEffect(() => {
        logoutUser();
        navigate('/');
    }, []);

    return (
        <div>
            <Helmet />
            <h1>Loggin out...</h1>
        </div>
    );
}

export default Logout;
