import React from 'react';
import { Row, Col, Form, Button, Breadcrumb, Alert } from 'react-bootstrap'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { addEmployeeAssignment, getEmployees, getProjects } from '../../ApiConnection';
import { useAuthStore } from '../../stores/AuthStore'
import Select from 'react-select'
import { getHelmet } from '../../UtilFunctions';

const CreateEmployeeAssignment = () => {
    const { token, tenant } = useAuthStore();
    const location = useLocation();
    const [customer, setCustomer] = React.useState(location?.state?.project ? { value: location.state.customer, label: location.state.customer_name } : {value: '', label: 'Empty'});
    const [employee, setEmployee] = React.useState(location?.state?.employee ? { value: location.state.employee, label: `${location.state.name} ${location.state.lastname}` } : '');
    const [project, setProject] = React.useState(location?.state?.project ? { value: {id: location.state.project, customer: location.state.customer_name }, label: location.state.project_name } : '');
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());
    const [renewalExpected, setRenewalExpected] = React.useState(false);
    const [billable, setBillable] = React.useState(true);
    const [allocationPercentage, setAllocationPercentage] = React.useState(50);
    const [error, setError] = React.useState();
    const [customers, setCustomers] = React.useState([]);
    const [employees, setEmployees] = React.useState([]);
    const [projects, setProjects] = React.useState([]);

    const navigate = useNavigate();
    const routeChange = (uuid) => {
        const path = `/assignments/${uuid}`;
        navigate(path);
    }

    const handleSubmit = async event => {
        event.preventDefault();

        try {
            const result = await addEmployeeAssignment(token, tenant, employee.value, project.value.id, startDate, endDate, renewalExpected, billable, allocationPercentage);
            routeChange(result.data.id);
        } catch (error) {
            setError(error.message)
        }
    }

    const getFilteredProjects = () => {
        return projects.filter(project => customer.value == project.value.customer);
    }

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Employee Assignment Creation', 'Creation of employee assignment', true);
    }

    React.useEffect(() => {
        async function getData() {
            try {
                const employees = (await getEmployees(token, tenant)).filter(emp => emp.active).sort((a, b) => a.name > b.name ? 1 : -1);
                const employeeArray = [];
                employees.forEach(element => {
                    employeeArray.push({ value: element.id, label: `${element.name} ${element.lastname}`})
                });
                setEmployees(employeeArray);
                const projects = await getProjects(token, tenant);
                const projectArray = [];
                const customerArray = [];
                const uniqueCustomers = [];
                projects.forEach(element => {
                    if(!uniqueCustomers.includes(element.customer)) {
                        uniqueCustomers.push(element.customer);
                        customerArray.push({value: element.customer, label: element.customer_name});
                    }
                    projectArray.push({value: {id: element.id, customer: element.customer}, label: `${element.name}`});
                });
                setCustomers(customerArray);
                setProjects(projectArray);
            } catch (e) {
                navigate(`/NotAuthenticated/${e.response.status}`, {
                    state: { prevLocation: location.pathname}
                });
                setError(e);
            }
        }
        getData();
    }, []);

    return (
        <div>
            <Helmet />
            <Breadcrumb className="mt-3">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/assignments" }} >Assignments</Breadcrumb.Item>
                <Breadcrumb.Item active>Create Employee Assignment</Breadcrumb.Item>
            </Breadcrumb>
            <form onSubmit={handleSubmit} >
                <Form.Group as={Col} className="mb-3">
                    <Form.Label>Employee <span className='text-danger'>*</span></Form.Label>
                    <Select className='create-assignment-form-employee'
                    value={employee}
                    onChange={setEmployee}
                    options={employees}
                    />
                </Form.Group>
                <Form.Group as={Col} className="mb-3">
                    <Form.Label>Customer <span className='text-danger'>*</span></Form.Label>
                    <Select
                    value={customer}
                    onChange={setCustomer}
                    options={customers}
                    />
                </Form.Group>
                <Form.Group as={Col} className="mb-3">
                    <Form.Label>Project <span className='text-danger'>*</span></Form.Label>
                    <Select
                    value={project}
                    onChange={setProject}
                    options={getFilteredProjects()}
                    />
                </Form.Group>
                <Row>
                <Form.Group as={Col} className="mb-3">
                    <Form.Label>Start Date <span className='text-danger'>*</span></Form.Label>
                    <Form.Control onChange={e => setStartDate(new Date(e.target.value))} type="date" required />
                </Form.Group>
                <Form.Group as={Col} className="mb-3">
                    <Form.Label>End Date <span className='text-danger'>*</span></Form.Label>
                    <Form.Control onChange={e => setEndDate(new Date(e.target.value))} type="date" required />
                </Form.Group>
                </Row>
                <Row>
                <Form.Group as={Col} className="mb-3">
                    <Form.Check type="switch" onChange={e => setRenewalExpected(e.target.checked)} label="Renewal Expected" checked={renewalExpected} />
                </Form.Group>
                <Form.Group as={Col} className="mb-3">
                    <Form.Check type="switch" onChange={e => setBillable(e.target.checked)} label="Billable" checked={billable} />
                </Form.Group>
                </Row>
                <Form.Group className="mb-3" as={Row}>
                    <Col xs="3">
                        <Form.Control value={allocationPercentage} />
                    </Col>
                    <Col xs="9">
                        <Form.Range
                            value={allocationPercentage}
                            onChange={e => setAllocationPercentage(e.target.value)}
                        />
                    </Col>
                </Form.Group>
                {(error &&
                    <Alert className='mb-3' variant="danger">
                        {error}
                    </Alert>)}
                <Button variant="dark" type="submit">Add employee assignment</Button>
            </form>
        </div>
    );
}

export default CreateEmployeeAssignment;
